import React from "react";
import { View, Heading, ScrollView } from "@aws-amplify/ui-react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";
import DisplayMetricsTable from "./DisplayMetricsTable";
import { MetricsSimpleValuesTable } from "@/data/fetchValues";
//table
import { GraphQLQuery } from "@aws-amplify/api";
import { useEffect, useState } from "react";
import * as queries from "../../graphql/queries";
import { ListMetricsQuery } from "@/API";
import { API } from "aws-amplify";
import { Flex } from "@aws-amplify/ui-react";



const MetricsTable = () => {
  const [metric, setMetric] = useState<MetricsSimpleValuesTable[]>();
  useEffect(() => {
    async function grabMetrics() {
      const allMetrics = await API.graphql<GraphQLQuery<ListMetricsQuery>>({
        query: queries.listMetrics,
      });
      setMetric(allMetrics.data?.listMetrics?.items as MetricsSimpleValuesTable[]);
    }
    grabMetrics();
  }, []);
  return (
    <>
      <div>
        <h2>Raportarile Mele</h2>
      </div>

      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth="100%"
        padding="1rem"
        minHeight="80vh"
      >
        <Heading color="#333"> </Heading>
        <br></br>
        <ScrollView width="100%" >
        {metric === undefined ? null : (
          <DisplayMetricsTable tableName=" - - " data={metric}/> 
        )}
        </ScrollView>
        <div>
          <Flex justifyContent={"center"} alignItems={"center"}>
        <Menu
        trigger={
          
          <MenuButton variation="link" color={"grey"}>Export..
          </MenuButton>
        }
      >
        <MenuItem size="small" onClick={() => alert("Sorry, not available yet")}>Selecteaza Perioada</MenuItem>
        <MenuItem size="small" onClick={() => alert("Sorry, not available yet")}>Toate</MenuItem>
      </Menu> 
      </Flex>
        </div>
      </View>
    </>
  );
};

export default MetricsTable;
