/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUserPreferences = /* GraphQL */ `query GetUserPreferences($id: ID!) {
  getUserPreferences(id: $id) {
    id
    weeklyReminder
    hasAcceptedGDPR
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPreferencesQueryVariables,
  APITypes.GetUserPreferencesQuery
>;
export const listUserPreferences = /* GraphQL */ `query ListUserPreferences(
  $filter: ModelUserPreferencesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      weeklyReminder
      hasAcceptedGDPR
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPreferencesQueryVariables,
  APITypes.ListUserPreferencesQuery
>;
export const getChargingHistory = /* GraphQL */ `query GetChargingHistory($id: ID!) {
  getChargingHistory(id: $id) {
    id
    chargeDate
    chargeLocation
    chargeType
    chargeQuantity
    chargeCost
    EVS {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChargingHistoryQueryVariables,
  APITypes.GetChargingHistoryQuery
>;
export const listChargingHistories = /* GraphQL */ `query ListChargingHistories(
  $filter: ModelChargingHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listChargingHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      chargeDate
      chargeLocation
      chargeType
      chargeQuantity
      chargeCost
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChargingHistoriesQueryVariables,
  APITypes.ListChargingHistoriesQuery
>;
export const getServiceHistory = /* GraphQL */ `query GetServiceHistory($id: ID!) {
  getServiceHistory(id: $id) {
    id
    odometer
    serviceIn
    serviceOut
    photo
    rootCause
    EVS {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceHistoryQueryVariables,
  APITypes.GetServiceHistoryQuery
>;
export const listServiceHistories = /* GraphQL */ `query ListServiceHistories(
  $filter: ModelServiceHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listServiceHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      odometer
      serviceIn
      serviceOut
      photo
      rootCause
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceHistoriesQueryVariables,
  APITypes.ListServiceHistoriesQuery
>;
export const getEV = /* GraphQL */ `query GetEV($id: ID!) {
  getEV(id: $id) {
    id
    friendlyName
    description
    grafanalink
    photo
    Metrics {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    modelID
    servicehistorys {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    status
    charginghistorys {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEVQueryVariables, APITypes.GetEVQuery>;
export const listEVS = /* GraphQL */ `query ListEVS($filter: ModelEVFilterInput, $limit: Int, $nextToken: String) {
  listEVS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListEVSQueryVariables, APITypes.ListEVSQuery>;
export const eVSByModelID = /* GraphQL */ `query EVSByModelID(
  $modelID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEVFilterInput
  $limit: Int
  $nextToken: String
) {
  eVSByModelID(
    modelID: $modelID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EVSByModelIDQueryVariables,
  APITypes.EVSByModelIDQuery
>;
export const getModel = /* GraphQL */ `query GetModel($id: ID!) {
  getModel(id: $id) {
    id
    brand
    modelName
    modelType
    yearOfProduction
    EVS {
      items {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    categoryID
    batteryCapacity
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetModelQueryVariables, APITypes.GetModelQuery>;
export const listModels = /* GraphQL */ `query ListModels(
  $filter: ModelModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      brand
      modelName
      modelType
      yearOfProduction
      EVS {
        nextToken
        __typename
      }
      categoryID
      batteryCapacity
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListModelsQueryVariables,
  APITypes.ListModelsQuery
>;
export const modelsByCategoryID = /* GraphQL */ `query ModelsByCategoryID(
  $categoryID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelModelFilterInput
  $limit: Int
  $nextToken: String
) {
  modelsByCategoryID(
    categoryID: $categoryID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      brand
      modelName
      modelType
      yearOfProduction
      EVS {
        nextToken
        __typename
      }
      categoryID
      batteryCapacity
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ModelsByCategoryIDQueryVariables,
  APITypes.ModelsByCategoryIDQuery
>;
export const getMetric = /* GraphQL */ `query GetMetric($id: ID!) {
  getMetric(id: $id) {
    id
    reportType
    retroDate
    kmDriven
    avgTravelSpeed
    cost
    mediumConsumption
    roadisCity
    roadisDN
    roadisHighway
    roadisOther
    passengers
    useCase
    temp
    evs {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    photo
    freetext
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMetricQueryVariables, APITypes.GetMetricQuery>;
export const listMetrics = /* GraphQL */ `query ListMetrics(
  $filter: ModelMetricFilterInput
  $limit: Int
  $nextToken: String
) {
  listMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reportType
      retroDate
      kmDriven
      avgTravelSpeed
      cost
      mediumConsumption
      roadisCity
      roadisDN
      roadisHighway
      roadisOther
      passengers
      useCase
      temp
      evs {
        nextToken
        __typename
      }
      photo
      freetext
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMetricsQueryVariables,
  APITypes.ListMetricsQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    grafanalink
    Models {
      items {
        id
        brand
        modelName
        modelType
        yearOfProduction
        categoryID
        batteryCapacity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      grafanalink
      Models {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const getChargingHistoryEV = /* GraphQL */ `query GetChargingHistoryEV($id: ID!) {
  getChargingHistoryEV(id: $id) {
    id
    chargingHistoryId
    eVId
    chargingHistory {
      id
      chargeDate
      chargeLocation
      chargeType
      chargeQuantity
      chargeCost
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChargingHistoryEVQueryVariables,
  APITypes.GetChargingHistoryEVQuery
>;
export const listChargingHistoryEVS = /* GraphQL */ `query ListChargingHistoryEVS(
  $filter: ModelChargingHistoryEVFilterInput
  $limit: Int
  $nextToken: String
) {
  listChargingHistoryEVS(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chargingHistoryId
      eVId
      chargingHistory {
        id
        chargeDate
        chargeLocation
        chargeType
        chargeQuantity
        chargeCost
        createdAt
        updatedAt
        owner
        __typename
      }
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChargingHistoryEVSQueryVariables,
  APITypes.ListChargingHistoryEVSQuery
>;
export const chargingHistoryEVSByChargingHistoryId = /* GraphQL */ `query ChargingHistoryEVSByChargingHistoryId(
  $chargingHistoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelChargingHistoryEVFilterInput
  $limit: Int
  $nextToken: String
) {
  chargingHistoryEVSByChargingHistoryId(
    chargingHistoryId: $chargingHistoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chargingHistoryId
      eVId
      chargingHistory {
        id
        chargeDate
        chargeLocation
        chargeType
        chargeQuantity
        chargeCost
        createdAt
        updatedAt
        owner
        __typename
      }
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChargingHistoryEVSByChargingHistoryIdQueryVariables,
  APITypes.ChargingHistoryEVSByChargingHistoryIdQuery
>;
export const chargingHistoryEVSByEVId = /* GraphQL */ `query ChargingHistoryEVSByEVId(
  $eVId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelChargingHistoryEVFilterInput
  $limit: Int
  $nextToken: String
) {
  chargingHistoryEVSByEVId(
    eVId: $eVId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      chargingHistoryId
      eVId
      chargingHistory {
        id
        chargeDate
        chargeLocation
        chargeType
        chargeQuantity
        chargeCost
        createdAt
        updatedAt
        owner
        __typename
      }
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChargingHistoryEVSByEVIdQueryVariables,
  APITypes.ChargingHistoryEVSByEVIdQuery
>;
export const getServiceHistoryEV = /* GraphQL */ `query GetServiceHistoryEV($id: ID!) {
  getServiceHistoryEV(id: $id) {
    id
    serviceHistoryId
    eVId
    serviceHistory {
      id
      odometer
      serviceIn
      serviceOut
      photo
      rootCause
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceHistoryEVQueryVariables,
  APITypes.GetServiceHistoryEVQuery
>;
export const listServiceHistoryEVS = /* GraphQL */ `query ListServiceHistoryEVS(
  $filter: ModelServiceHistoryEVFilterInput
  $limit: Int
  $nextToken: String
) {
  listServiceHistoryEVS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      serviceHistoryId
      eVId
      serviceHistory {
        id
        odometer
        serviceIn
        serviceOut
        photo
        rootCause
        createdAt
        updatedAt
        owner
        __typename
      }
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceHistoryEVSQueryVariables,
  APITypes.ListServiceHistoryEVSQuery
>;
export const serviceHistoryEVSByServiceHistoryId = /* GraphQL */ `query ServiceHistoryEVSByServiceHistoryId(
  $serviceHistoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServiceHistoryEVFilterInput
  $limit: Int
  $nextToken: String
) {
  serviceHistoryEVSByServiceHistoryId(
    serviceHistoryId: $serviceHistoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceHistoryId
      eVId
      serviceHistory {
        id
        odometer
        serviceIn
        serviceOut
        photo
        rootCause
        createdAt
        updatedAt
        owner
        __typename
      }
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServiceHistoryEVSByServiceHistoryIdQueryVariables,
  APITypes.ServiceHistoryEVSByServiceHistoryIdQuery
>;
export const serviceHistoryEVSByEVId = /* GraphQL */ `query ServiceHistoryEVSByEVId(
  $eVId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServiceHistoryEVFilterInput
  $limit: Int
  $nextToken: String
) {
  serviceHistoryEVSByEVId(
    eVId: $eVId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceHistoryId
      eVId
      serviceHistory {
        id
        odometer
        serviceIn
        serviceOut
        photo
        rootCause
        createdAt
        updatedAt
        owner
        __typename
      }
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServiceHistoryEVSByEVIdQueryVariables,
  APITypes.ServiceHistoryEVSByEVIdQuery
>;
export const getEVMetric = /* GraphQL */ `query GetEVMetric($id: ID!) {
  getEVMetric(id: $id) {
    id
    eVId
    metricId
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    metric {
      id
      reportType
      retroDate
      kmDriven
      avgTravelSpeed
      cost
      mediumConsumption
      roadisCity
      roadisDN
      roadisHighway
      roadisOther
      passengers
      useCase
      temp
      evs {
        nextToken
        __typename
      }
      photo
      freetext
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEVMetricQueryVariables,
  APITypes.GetEVMetricQuery
>;
export const listEVMetrics = /* GraphQL */ `query ListEVMetrics(
  $filter: ModelEVMetricFilterInput
  $limit: Int
  $nextToken: String
) {
  listEVMetrics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eVId
      metricId
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      metric {
        id
        reportType
        retroDate
        kmDriven
        avgTravelSpeed
        cost
        mediumConsumption
        roadisCity
        roadisDN
        roadisHighway
        roadisOther
        passengers
        useCase
        temp
        photo
        freetext
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEVMetricsQueryVariables,
  APITypes.ListEVMetricsQuery
>;
export const eVMetricsByEVId = /* GraphQL */ `query EVMetricsByEVId(
  $eVId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEVMetricFilterInput
  $limit: Int
  $nextToken: String
) {
  eVMetricsByEVId(
    eVId: $eVId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eVId
      metricId
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      metric {
        id
        reportType
        retroDate
        kmDriven
        avgTravelSpeed
        cost
        mediumConsumption
        roadisCity
        roadisDN
        roadisHighway
        roadisOther
        passengers
        useCase
        temp
        photo
        freetext
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EVMetricsByEVIdQueryVariables,
  APITypes.EVMetricsByEVIdQuery
>;
export const eVMetricsByMetricId = /* GraphQL */ `query EVMetricsByMetricId(
  $metricId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEVMetricFilterInput
  $limit: Int
  $nextToken: String
) {
  eVMetricsByMetricId(
    metricId: $metricId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eVId
      metricId
      eV {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      metric {
        id
        reportType
        retroDate
        kmDriven
        avgTravelSpeed
        cost
        mediumConsumption
        roadisCity
        roadisDN
        roadisHighway
        roadisOther
        passengers
        useCase
        temp
        photo
        freetext
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EVMetricsByMetricIdQueryVariables,
  APITypes.EVMetricsByMetricIdQuery
>;
