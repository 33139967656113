/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { API } from "aws-amplify";
import { listCategories } from "../graphql/queries";
import { createModel } from "../graphql/mutations";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function ModelCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    brand: "",
    modelName: "",
    modelType: "",
    yearOfProduction: "",
    batteryCapacity: "",
    categoryID: undefined,
  };
  const [brand, setBrand] = React.useState(initialValues.brand);
  const [modelName, setModelName] = React.useState(initialValues.modelName);
  const [modelType, setModelType] = React.useState(initialValues.modelType);
  const [yearOfProduction, setYearOfProduction] = React.useState(
    initialValues.yearOfProduction
  );
  const [batteryCapacity, setBatteryCapacity] = React.useState(
    initialValues.batteryCapacity
  );
  const [categoryID, setCategoryID] = React.useState(initialValues.categoryID);
  const [categoryIDLoading, setCategoryIDLoading] = React.useState(false);
  const [categoryIDRecords, setCategoryIDRecords] = React.useState([]);
  const [selectedCategoryIDRecords, setSelectedCategoryIDRecords] =
    React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setBrand(initialValues.brand);
    setModelName(initialValues.modelName);
    setModelType(initialValues.modelType);
    setYearOfProduction(initialValues.yearOfProduction);
    setBatteryCapacity(initialValues.batteryCapacity);
    setCategoryID(initialValues.categoryID);
    setCurrentCategoryIDValue(undefined);
    setCurrentCategoryIDDisplayValue("");
    setErrors({});
  };
  const [currentCategoryIDDisplayValue, setCurrentCategoryIDDisplayValue] =
    React.useState("");
  const [currentCategoryIDValue, setCurrentCategoryIDValue] =
    React.useState(undefined);
  const categoryIDRef = React.createRef();
  const getDisplayValue = {
    categoryID: (r) => `${r?.name}`,
  };
  const validations = {
    brand: [
      { type: "Required" },
      {
        type: "LessThanChar",
        numValues: [15],
        validationMessage: "The value must be 15 characters or fewer",
      },
      {
        type: "NotContains",
        strValues: [
          "!, @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], \", ', ;, :",
        ],
        validationMessage:
          'The value must not contain "!, @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], ", \', ;, :"',
      },
    ],
    modelName: [
      { type: "Required" },
      {
        type: "LessThanChar",
        numValues: [40],
        validationMessage: "The value must be 40 characters or fewer",
      },
      {
        type: "NotContains",
        strValues: [
          ", @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], \", ', ;, :",
        ],
        validationMessage:
          'The value must not contain ", @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], ", \', ;, :"',
      },
    ],
    modelType: [
      { type: "Required" },
      {
        type: "LessThanChar",
        numValues: [45],
        validationMessage: "The value must be 45 characters or fewer",
      },
      {
        type: "NotContains",
        strValues: [
          ", @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], \", ', ;, :",
        ],
        validationMessage:
          'The value must not contain ", @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], ", \', ;, :"',
      },
    ],
    yearOfProduction: [
      { type: "Required" },
      {
        type: "NotContains",
        strValues: [
          "!, @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], \", ', ;, :",
        ],
        validationMessage:
          'The value must not contain "!, @, #, $, %, ^, &, *, (, ), _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], ", \', ;, :"',
      },
      {
        type: "NotContains",
        strValues: [
          "q, w, e, r, t, y, u, i, o, p, a, s, d, f, g, h, j, k, l, z, x, c, v, b, n, m",
        ],
        validationMessage:
          'The value must not contain "q, w, e, r, t, y, u, i, o, p, a, s, d, f, g, h, j, k, l, z, x, c, v, b, n, m"',
      },
      {
        type: "NotContains",
        strValues: [
          "Q, W, E, R, T, Y, U, I, O, P, A, S, D, F, G, H, J, K, L, Z, X, C, V, B, N, M",
        ],
        validationMessage:
          'The value must not contain "Q, W, E, R, T, Y, U, I, O, P, A, S, D, F, G, H, J, K, L, Z, X, C, V, B, N, M"',
      },
      {
        type: "LessThanChar",
        numValues: [4],
        validationMessage: "The value must be 4 characters or fewer",
      },
    ],
    batteryCapacity: [
      { type: "Required" },
      {
        type: "NotContains",
        strValues: ["10"],
        validationMessage: 'The value must not contain "10"',
      },
      {
        type: "NotContains",
        strValues: [
          "q, w, e, r, t, y, u, i, o, p, a, s, d, f, g, h, j, k, l, z, x, c, v, b, n, m",
        ],
        validationMessage:
          'The value must not contain "q, w, e, r, t, y, u, i, o, p, a, s, d, f, g, h, j, k, l, z, x, c, v, b, n, m"',
      },
      {
        type: "NotContains",
        strValues: [
          "Q, W, E, R, T, Y, U, I, O, P, A, S, D, F, G, H, J, K, L, Z, X, C, V, B, N, M",
        ],
        validationMessage:
          'The value must not contain "Q, W, E, R, T, Y, U, I, O, P, A, S, D, F, G, H, J, K, L, Z, X, C, V, B, N, M"',
      },
      {
        type: "NotContains",
        strValues: [
          "!, @, #, $, %, ^, &, *, _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], \", ', ;, :",
        ],
        validationMessage:
          'The value must not contain "!, @, #, $, %, ^, &, *, _, +, =, ~, `, ,, <, >, ?, /, \\, |, \u00B1, \u00A7, {, }, [, ], ", \', ;, :"',
      },
    ],
    categoryID: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchCategoryIDRecords = async (value) => {
    setCategoryIDLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: { or: [{ name: { contains: value } }] },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await API.graphql({
          query: listCategories.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listCategories?.items;
      var loaded = result.filter((item) => categoryID !== item.id);
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setCategoryIDRecords(newOptions.slice(0, autocompleteLength));
    setCategoryIDLoading(false);
  };
  React.useEffect(() => {
    fetchCategoryIDRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          brand,
          modelName,
          modelType,
          yearOfProduction,
          batteryCapacity,
          categoryID,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await API.graphql({
            query: createModel.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "ModelCreateForm")}
      {...rest}
    >
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>BRAND:</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        descriptiveText="Introduceti doar numele simplu al brand-ului."
        isRequired={true}
        isReadOnly={false}
        placeholder='Ex: "Renault" sau "Tesla" sau "Kia", etc.'
        value={brand}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              brand: value,
              modelName,
              modelType,
              yearOfProduction,
              batteryCapacity,
              categoryID,
            };
            const result = onChange(modelFields);
            value = result?.brand ?? value;
          }
          if (errors.brand?.hasError) {
            runValidationTasks("brand", value);
          }
          setBrand(value);
        }}
        onBlur={() => runValidationTasks("brand", brand)}
        errorMessage={errors.brand?.errorMessage}
        hasError={errors.brand?.hasError}
        {...getOverrideProps(overrides, "brand")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Numele Modelului:</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        placeholder='Ex: "ZOE", "Model Y", "Niro"'
        value={modelName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              brand,
              modelName: value,
              modelType,
              yearOfProduction,
              batteryCapacity,
              categoryID,
            };
            const result = onChange(modelFields);
            value = result?.modelName ?? value;
          }
          if (errors.modelName?.hasError) {
            runValidationTasks("modelName", value);
          }
          setModelName(value);
        }}
        onBlur={() => runValidationTasks("modelName", modelName)}
        errorMessage={errors.modelName?.errorMessage}
        hasError={errors.modelName?.hasError}
        {...getOverrideProps(overrides, "modelName")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Tipul de Model</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        placeholder='Ex: "ZE 40 LIFE", "LR", "EV"'
        value={modelType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              brand,
              modelName,
              modelType: value,
              yearOfProduction,
              batteryCapacity,
              categoryID,
            };
            const result = onChange(modelFields);
            value = result?.modelType ?? value;
          }
          if (errors.modelType?.hasError) {
            runValidationTasks("modelType", value);
          }
          setModelType(value);
        }}
        onBlur={() => runValidationTasks("modelType", modelType)}
        errorMessage={errors.modelType?.errorMessage}
        hasError={errors.modelType?.hasError}
        {...getOverrideProps(overrides, "modelType")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Anul Fabricatiei:</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        placeholder='Ex: "2016", "2020", "2023"'
        value={yearOfProduction}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              brand,
              modelName,
              modelType,
              yearOfProduction: value,
              batteryCapacity,
              categoryID,
            };
            const result = onChange(modelFields);
            value = result?.yearOfProduction ?? value;
          }
          if (errors.yearOfProduction?.hasError) {
            runValidationTasks("yearOfProduction", value);
          }
          setYearOfProduction(value);
        }}
        onBlur={() => runValidationTasks("yearOfProduction", yearOfProduction)}
        errorMessage={errors.yearOfProduction?.errorMessage}
        hasError={errors.yearOfProduction?.hasError}
        {...getOverrideProps(overrides, "yearOfProduction")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Capacitatea Bateriei:</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        descriptiveText="Introduceti doar cifra(valoarea) exprimata in kWh "
        isRequired={true}
        isReadOnly={false}
        placeholder='Ex: "41.0" , "78 (75)", "67.5 (64)"'
        value={batteryCapacity}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              brand,
              modelName,
              modelType,
              yearOfProduction,
              batteryCapacity: value,
              categoryID,
            };
            const result = onChange(modelFields);
            value = result?.batteryCapacity ?? value;
          }
          if (errors.batteryCapacity?.hasError) {
            runValidationTasks("batteryCapacity", value);
          }
          setBatteryCapacity(value);
        }}
        onBlur={() => runValidationTasks("batteryCapacity", batteryCapacity)}
        errorMessage={errors.batteryCapacity?.errorMessage}
        hasError={errors.batteryCapacity?.hasError}
        {...getOverrideProps(overrides, "batteryCapacity")}
      ></TextField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              brand,
              modelName,
              modelType,
              yearOfProduction,
              batteryCapacity,
              categoryID: value,
            };
            const result = onChange(modelFields);
            value = result?.categoryID ?? value;
          }
          setCategoryID(value);
          setCurrentCategoryIDValue(undefined);
        }}
        currentFieldValue={currentCategoryIDValue}
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Categorie:</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        items={categoryID ? [categoryID] : []}
        hasError={errors?.categoryID?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("categoryID", currentCategoryIDValue)
        }
        errorMessage={errors?.categoryID?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.categoryID(
                categoryIDRecords.find((r) => r.id === value) ??
                  selectedCategoryIDRecords.find((r) => r.id === value)
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentCategoryIDDisplayValue(
            value
              ? getDisplayValue.categoryID(
                  categoryIDRecords.find((r) => r.id === value) ??
                    selectedCategoryIDRecords.find((r) => r.id === value)
                )
              : ""
          );
          setCurrentCategoryIDValue(value);
          const selectedRecord = categoryIDRecords.find((r) => r.id === value);
          if (selectedRecord) {
            setSelectedCategoryIDRecords([selectedRecord]);
          }
        }}
        inputFieldRef={categoryIDRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label={
            <span style={{ display: "inline-flex" }}>
              <span>Categorie:</span>
              <span style={{ color: "red" }}>*</span>
            </span>
          }
          descriptiveText='Va rugam selectati "Fara Categorie" daca nu stiti categoria exacta - si nu uitati sa apasati "Add" in dreapta.'
          isRequired={true}
          isReadOnly={false}
          placeholder='Selectati "Fara Categorie" - apoi "Add" in dreapta.'
          value={currentCategoryIDDisplayValue}
          options={categoryIDRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex((member) => member?.id === r?.id) === i
            )
            .map((r) => ({
              id: r?.id,
              label: getDisplayValue.categoryID?.(r),
            }))}
          isLoading={categoryIDLoading}
          onSelect={({ id, label }) => {
            setCurrentCategoryIDValue(id);
            setCurrentCategoryIDDisplayValue(label);
            runValidationTasks("categoryID", label);
          }}
          onClear={() => {
            setCurrentCategoryIDDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchCategoryIDRecords(value);
            if (errors.categoryID?.hasError) {
              runValidationTasks("categoryID", value);
            }
            setCurrentCategoryIDDisplayValue(value);
            setCurrentCategoryIDValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("categoryID", currentCategoryIDValue)
          }
          errorMessage={errors.categoryID?.errorMessage}
          hasError={errors.categoryID?.hasError}
          ref={categoryIDRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "categoryID")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Înregistrează Acest Model Nou"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
