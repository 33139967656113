import React, {
  useEffect, 
  useState 
} from "react";
import { 
  Flex, 
  Text,
  View, 
  Authenticator,
} from "@aws-amplify/ui-react";
import { Auth } from "@aws-amplify/auth";
//hooks:
import { useUserHasEVs } from '../hooks/useUserHasEVs';
//import { useUserHasGDPRSigned } from "../hooks/useUserHasGDPRSigned";

interface UserAttributes {
  picture: string;
  profile: string;
  // other attributes
}
  const ProfileHeader = () => {
  // console.log(props);
  const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(null);
  useUserHasEVs();
  //useUserHasGDPRSigned(); needs ID implementation other release.
  
  
  
  useEffect(() => {
    async function fetchAttributes() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
        const attributes = currentUser.attributes;
        // console.log(attributes)
        setUserAttributes(attributes);
      } catch (error) {
        console.log(error);
      }
    }

    fetchAttributes();
    
  }, []);

  return (
    <>
      <Flex
        direction={{ base: "column", large: "row" }}
        alignItems="flex-start"
      >
        
        <div className="profile-header-image">
        <img 
              alt="avatar" 
              src={
                userAttributes?.picture 
                ? userAttributes.picture 
                : "https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-man-avatar-wearing-blue-t-shirt-png-image_5809518.png"
                  }/>
        </div>
        <div className="profile-header-text">
          Salut,
          <Text variation="primary" fontWeight={600} fontSize="18px">
          <Authenticator>
                  {({ user }) => (
                      <View textAlign="left">
                      {user?.attributes?.name}
                      </View>
                  )}
                </Authenticator>
          </Text>
        </div>
      </Flex>
    </>
  );
};

export default ProfileHeader;