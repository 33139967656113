import React from "react";
import {
  View
} from "@aws-amplify/ui-react";
import ServiceHistory from "../../ui-components/ServiceHistoryCreateForm";
import { useNavigate } from "react-router-dom";
//hooks:
import { useUserHasEVs } from '../hooks/useUserHasEVs';


const AddServiceHistory = () => {
  useUserHasEVs();
  const navigate = useNavigate();
  const handleSuccess = () => {
    alert('Multumim. Adaugat cu Succes.');
    navigate('/display-user-servicehistory');
  };
  return (
    <>
          <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <ServiceHistory 
        onSuccess={handleSuccess} 
        onError={() => alert("Ne pare rau a aparut o Eroare. Daca eroarea persista va rugam sa ne spuneti")} 
        />
      </View>
    </>
  );
};

export default AddServiceHistory;