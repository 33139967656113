import React from "react";
import { 
  Flex,
  Text,
  View,  
  Authenticator, 
} from "@aws-amplify/ui-react";

const ProfileInformation = () => {

  return (
    <div className="profile-card-content">
      <Text fontWeight="600" fontSize="18px" marginBottom="14px">
        Profile Information
      </Text>
      <Flex>
        <Text variation="tertiary" fontWeight="600">
          Full Name:
        </Text>
        <Authenticator>
                  {({ user }) => (
                      <View textAlign="left">
                      {user?.attributes?.name}
                      </View>
                  )}
                </Authenticator>
      </Flex>
      {/* <Flex>
        <Text variation="tertiary" fontWeight="600">
          Phone:
        </Text>
        <Text variation="tertiary">
        <Authenticator>
                  {({ user }) => (
                      <View textAlign="left">
                      {user?.attributes?.phone_number}
                      </View>
                  )}
                </Authenticator> 
        </Text>
      </Flex> */}
      <Flex>
        <Text variation="tertiary" fontWeight="600">
          Email:
        </Text>
        <Text variation="tertiary">
        <Authenticator>
                  {({ user }) => (
                      <View textAlign="left">
                      {user?.attributes?.email}
                      </View>
                  )}
                </Authenticator> 
        </Text>
      </Flex>
      {/* <Flex>
        <Text variation="tertiary" fontWeight="600">
          Location:
        </Text>
        <Text variation="tertiary">
          not available yet
        </Text>
      </Flex> */}
      {/* <div className="profile-card-edit">
        <Button marginLeft="auto" onClick={() => alert("Sorry, not available yet")}>Edit</Button>
      </div> */}
    </div>
  );
};

export default ProfileInformation;
