import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GraphQLQuery } from "@aws-amplify/api";
import { ListEVSQuery } from "@/API";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { EVValues } from "@/data/fetchValues";

export const useUserHasEVs = () => {
  const [hasEVs, setHasEVs] = useState(true); // New state for storing the status of user's EVs
  const navigate = useNavigate(); // react-router-dom's navigate function
  
  useEffect(() => {
    // Copied from ProfileEV.tsx
    async function grabEV() {
      const allEVS = await API.graphql<GraphQLQuery<ListEVSQuery>>({
        query: queries.listEVS,
      });
      const evs = allEVS.data?.listEVS?.items as EVValues[];
      if (evs === null || evs.length === 0) {
        // If evs is null, set the state to false. This will trigger a page redirection.
        setHasEVs(false);
      }
    }
    grabEV();
  }, []);

  // If the user does not have EVs, navigate to '/welcome'
  if (!hasEVs) {
    navigate("/welcome");
  }
}