import React from "react";
import { View, Heading } from "@aws-amplify/ui-react";
import ServiceHistoryUpdateForm from "../../ui-components/ServiceHistoryUpdateForm";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const UpdateServiceHistory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleSuccess = () => {
    alert('Multumim. Adaugat cu Succes.');
    navigate('/display-user-servicehistory');
  };

  return (
    <>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <Heading color="#333"> Modifica Intrarea in Service </Heading>
        <ServiceHistoryUpdateForm 
        id={id}
        onSuccess={handleSuccess} 
        onError={() => alert("Ne pare rau a aparut o Eroare. Daca eroarea persista va rugam sa ne spuneti")}         
        />
      </View>
    </>
  );
};

export default UpdateServiceHistory;
