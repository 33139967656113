import React from "react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

//table imports:
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { ServiceHistoryValues } from "@/data/fetchValues";
// First, import the deleteServiceHistorymutation
import * as mutations from "../../../graphql/mutations";
import { API } from "aws-amplify";
import { useState } from "react";
import { 
  MdModeEdit,
  MdDriveFileRenameOutline,
  MdDelete,
  MdOutlinePictureAsPdf 
} from "react-icons/md";

interface ItemsTableProps {
  tableName: string;
  data: ServiceHistoryValues[];
}

export default function ServiceHistoryTable({ tableName, data }: ItemsTableProps) {
    const navigate = useNavigate();
      // Sort data by createdAt
    const sortedData = [...data].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    // The deleteServiceHistoryfunction could look something like this:
    const [service, setService] = useState<ServiceHistoryValues[]>(); 
    const deleteServiceHistory = async (id: string) => {
      // Confirm with the user
    const confirm = window.confirm("Sunteti sigur/a ca doriti sa stergeti aceasta intrare in service ?");
    if (!confirm) return;  // If the user clicks "Cancel", stop here
      try {
        const serviceToDelete = {
          id, // Id of the ServiceHistoryyou want to delete
        };
  
        await API.graphql({
          query: mutations.deleteServiceHistory, 
          variables: { input: serviceToDelete } 
        });
  
        // Then you can update your local state to remove the deleted metric
        if (service) {
          setService(service.filter(item => item.id !== id));
        }
        // After deletion, navigate and refresh the page:
        // window.location.href = "/display-user-servicehistory";
        navigate('/');
      } catch (error) {
        console.log("Error deleting service history:", error);
      }
    };  
  return (
    <Table caption={tableName}>
      <TableHead>
        <TableRow>
          <TableCell as="th">Odometru</TableCell>
          <TableCell as="th">Data Intrare Service</TableCell>
          <TableCell as="th">Data Iesire Service</TableCell>
          <TableCell as="th">Scurt Detaliu</TableCell>
          <TableCell as="th">Poza</TableCell>
          <TableCell as="th">Actiuni</TableCell>                     
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map((item, key) => (
          <TableRow key={key}>
            <TableCell>{item.odometer}</TableCell>
            <TableCell>
              {Intl.DateTimeFormat("ro-RO").format(new Date(item?.serviceIn!))}
            </TableCell>
            <TableCell>
              {Intl.DateTimeFormat("ro-RO").format(new Date(item?.serviceOut!))}
            </TableCell>            
            <TableCell>{item?.rootCause}</TableCell>
            <TableCell >
            {
              item?.photo === null || item?.photo === undefined ?
              (
                <MdOutlinePictureAsPdf />  
              ) :
              (
                item?.photo.endsWith('.pdf') ? <MdOutlinePictureAsPdf /> :
                (
                  <StorageImage
                    width={"18rem"}
                    accessLevel="private"
                    alt="image"
                    imgKey={item?.photo}>
                  </StorageImage>
                )
              )
            }
            </TableCell>
            <TableCell as="th">
            <Menu
        menuAlign="end"
        trigger={
          
          <MenuButton variation="primary" size="small" color={"ButtonHighlight"}>
            <MdModeEdit />
          </MenuButton>
        }
      >
        <MenuItem size="small" onClick={() => navigate(`/updateservice/${item.id}`)} ><MdDriveFileRenameOutline /> EDIT</MenuItem>
        <MenuItem 
          size="small" 
          variation="destructive" 
          color={"red.40"} 
          onClick={() => deleteServiceHistory(item.id)}  // Call deleteMetric with the id of the item
        >
          <MdDelete /> DELETE
        </MenuItem>
      </Menu>
              
              
              </TableCell>                              
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}