import React from "react";
import { View, Heading, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { AcceptGDPRCreate } from "../../ui-components";
//import MyAcceptGDPRCreateForm from './MyAcceptGDPRCreateForm';


const GdprPage = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/addcar");
  };

  const handleBack = () => {
    navigate("/welcome");
  };

  return (
    <View
      backgroundColor="var(--amplify-colors-white)"
      borderRadius="6px"
      maxWidth={{ base: "100%", large: "100%" }}
      padding="1rem"
      minHeight="100vh"
      className="gdpr-page"
    >
        <View textAlign="center" >
        <Heading color="#333"> Acord GDPR - Aplicația Electromobilitate </Heading>
        <br></br>
        <img
                style={{ objectFit: "scale-down", width: "800px", height: "300px" }}
                src={require("./gdpr.png")}
                alt="gdpr"
              >
        </img>
        </View>
        <br></br>
        <Text>
<View textAlign="center" textTransform={"uppercase"}>Introducere </View>
<br></br>
Aplicația Electromobilitate ("Aplicația") este o platformă online concepută pentru a facilita colectarea și procesarea datelor/metricilor legate de mobilitatea electrică, cu scopul promovării unui viitor mai sustenabil. Prin intermediul Aplicației, utilizatorii pot contribui la o bază de date cuprinzătoare care va sprijini eforturile de cercetare și advocacy în domeniul electromobilității.<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Colectarea datelor </View>
<br></br>
Aplicația colectează o cantitate minimă de date de la utilizatori, strict necesară funcționării platformei și obținerii obiectivelor sale. Singura informație personală solicitată este adresa de email a utilizatorului. Această adresă este utilizată exclusiv pentru a trimite notificări și a facilita comunicarea cu utilizatorii în cazul unor probleme tehnice sau actualizări importante ale Aplicației.<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Stocarea datelor </View>
<br></br>
Parolele utilizatorilor nu sunt stocate niciodată de către Aplicație. Datele de autentificare sunt gestionate prin intermediul unui sistem de autentificare securizat, care utilizează criptare robustă pentru a proteja informațiile sensibile.<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Utilizarea datelor</View>
<br></br>
Metricile legate de vehiculele electrice ale utilizatorilor sunt colectate cu consimțământul explicit al acestora. Aceste date sunt agregate și anonimizate înainte de a fi utilizate în scopuri de analiză sau cercetare. Informațiile individuale nu sunt niciodată partajate cu terțe părți fără consimțământul explicit al utilizatorului.<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Scopul utilizării datelor </View>
<br></br>
Datele colectate prin intermediul Aplicației sunt utilizate exclusiv în următoarele scopuri:
<br></br>
Promovarea mobilității electrice: Datele agregate și anonimizate sunt utilizate pentru a genera statistici și rapoarte care pot informa politicile publice și pot contribui la accelerarea adoptării vehiculelor electrice.
Cercetare și dezvoltare: Datele pot fi utilizate în scopuri de cercetare și dezvoltare, cu scopul de a îmbunătăți performanța Aplicației și a oferi o experiență mai bună utilizatorilor.
Comunicare: Adresa de email a utilizatorului poate fi utilizată pentru a trimite notificări legate de Aplicație, actualizări importante sau informații relevante despre mobilitatea electrică.<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Drepturile utilizatorilor </View>
<br></br>
Utilizatorii au următoarele drepturi cu privire la datele lor personale:
<br></br>
Dreptul de acces: Utilizatorii pot solicita accesul la datele lor personale colectate de către Aplicație.
<br></br>
Dreptul de rectificare: Utilizatorii pot solicita corectarea oricăror informații incorecte sau incomplete din datele lor personale.
<br></br>
Dreptul de ștergere: Utilizatorii pot solicita ștergerea datelor lor personale în anumite circumstanțe.
<br></br>
Dreptul la restricționarea prelucrării: Utilizatorii pot solicita restricționarea prelucrării datelor lor personale în anumite circumstanțe.
<br></br>
Dreptul de opoziție la prelucrare: Utilizatorii pot obține ca Aplicația să nu mai prelucreze datele lor personale în anumite circumstanțe.
<br></br>
Dreptul la portabilitatea datelor: Utilizatorii pot solicita ca datele lor personale să le fie furnizate într-un format structurat, utilizat pe scară largă și lizibil de către un computer.<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Modificări ale Acordului GDPR </View>
<br></br>
Acest Acord GDPR poate fi modificat periodic. Utilizatorii vor fi notificați cu privire la orice modificare semnificativă prin intermediul Aplicației sau prin email.<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Contact</View>
<br></br>
Pentru orice întrebări sau solicitări legate de datele dumneavoastră personale, vă rugăm să contactați echipa noastră de asistență la [office@electromobilitate.com]<br></br>
<br></br>
<View textAlign="center" textTransform={"uppercase"}>Consimțământ </View>
<br></br>
Prin utilizarea Aplicației, sunteți de acord cu termenii și condițiile prezentului Acord GDPR.  
        </Text>
        <br />

        <AcceptGDPRCreate onSuccess={handleNext} onCancel={handleBack}/>


        <Text>
        Notă: Odată ce acceptați termenii GDPR, nu veți mai fi nevoiți să
        faceți acest lucru din nou la accesările ulterioare. JavaScript
        </Text>

      <br />

    </View>
  );
};

export default GdprPage;