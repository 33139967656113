import React, { useEffect, useState } from "react";
import {
  View,
  Grid,
  Flex,
  Card,
  Placeholder,
  useTheme,
} from "@aws-amplify/ui-react";
import { 
  MdRemoveRedEye, 
  MdEditRoad,
  MdCarRepair,
  MdEvStation 
} from "react-icons/md";

import MiniStatistics from "./MiniStatistics";
import TrafficSources from "./TrafficSources";
import SalesSummary from "./SalesSummary";
import TrafficSummary from "./TrafficSummary";
import CustomersSummary from "./CustomersSummary";

import "./Dashboard.css";
//grafql
import * as queries from "../../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api"
import { ListChargingHistoriesQuery, ListMetricsQuery, ListServiceHistoriesQuery } from "@/API";
import { API } from "aws-amplify";
import {MetricsSimpleValuesTable, ServiceHistoryValues, Incarcare, BarChartRoadType, ChargingMetrics, ChargingPlaces, ChargingHistoryValues} from "../../data/fetchValues" 
//hooks:
import { useUserHasEVs } from '../hooks/useUserHasEVs';
/// Mock Data
const barChartDataDemo = [
  {
    name: "Web",
    data: [
      11, 8, 9, 10, 3, 11, 11, 11, 12, 13, 2, 12, 5, 8, 22, 6, 8, 6, 4, 1, 8,
      24, 29, 51, 40, 47, 23, 26, 50, 26, 22, 27, 46, 47, 81, 46, 40,
    ],
  },
  {
    name: "Social",
    data: [
      7, 5, 4, 3, 3, 11, 4, 7, 5, 12, 12, 15, 13, 12, 6, 7, 7, 1, 5, 5, 2, 12,
      4, 6, 18, 3, 5, 2, 13, 15, 20, 47, 18, 15, 11, 10, 9,
    ],
  },
  {
    name: "Other",
    data: [
      4, 9, 11, 7, 8, 3, 6, 5, 5, 4, 6, 4, 11, 10, 3, 6, 7, 5, 2, 8, 4, 9, 9, 2,
      6, 7, 5, 1, 8, 3, 12, 3, 4, 9, 7, 11, 10,
    ],
  },
];



// const lineChartData = [
//   {
//     name: "Mobile apps",
//     data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
//   },
//   {
//     name: "Websites",
//     data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
//   },
// ];



const getChartData = () =>
  new Promise((resolve, reject) => {
    if (!barChartDataDemo) {
      return setTimeout(() => reject(new Error("no data")), 750);
    }

    setTimeout(() => resolve(Object.values(barChartDataDemo)), 750);
  });

const Dashboard = () => {
  useUserHasEVs();
  const [barChartData, setBarChartData] = useState<any | null>(null);
  const [trafficSourceData, setTrafficSourceData] = useState<any | null>(null);
  const { tokens } = useTheme();
  const [metric, setMetric] = useState<MetricsSimpleValuesTable[]>();
  const [service, setService] = useState<ServiceHistoryValues[]>();
  const [road, setRoad] = useState<BarChartRoadType[]>();
  // new charts 10.01.2024
  const [cost, setCost] = useState<ChargingMetrics[]>();
  const [place, setPlace] = useState<ChargingPlaces[]>();
  const [chargetype, setChargeType] = useState<ChargingHistoryValues[]>();
  const [chargequantity, setChargeQuantity] = useState<ChargingHistoryValues[]>();

    //Total Metrici Inregistrate
    const TotalRegisteredCharges = chargetype === undefined 
    ? '0' 
    : chargetype.length.toString();

  //sorting ChargeQuantityDates
  const chargeQuantityDates: string[] = chargequantity === undefined 
  ? [] 
  : chargequantity
      .sort((a, b) => a.chargeDate && b.chargeDate ? new Date(a.chargeDate).getTime() - new Date(b.chargeDate).getTime() : 0)
      //.filter(item => (item.retroDate === null || item.retroDate === undefined) )
      .map((item) => String(Intl.DateTimeFormat("en-EN").format(new Date(item?.chargeDate!))));

  const chargeQuantityData = [
    {
      name: "Charge Quantity",
      data: chargequantity === undefined 
        ? [] 
        : chargequantity
            .sort((a, b) => a.chargeDate && b.chargeDate ? new Date(a.chargeDate).getTime() - new Date(b.chargeDate).getTime() : 0)
            .map(item => item.chargeQuantity),
    }
  ];

    // Calculate counts for charging locations 
  let acasaCount = 0;
  let loculDeMuncaCount = 0;
  let reteaPublicaCount = 0;
  let alteleCount = 0;

  if (place) {
    place.forEach(p => {
      switch(p.chargeLocation) {
        case 'Acasa':
          acasaCount++;
          break;
        case 'Locul De Munca':
          loculDeMuncaCount++;
          break;
        case 'Retea Publica':
          reteaPublicaCount++;
          break;
        case 'Altele':
          alteleCount++;
          break;
        default:
          break;
      }
    });
  }

// Prepare chart data
const locationData = [
  acasaCount,
  loculDeMuncaCount,
  reteaPublicaCount,
  alteleCount,
];

    // Calculate counts for charging types
    let acCount = 0;
    let dcCount = 0;
  
    if (chargetype) {
      chargetype.forEach(p => {
        switch(p.chargeType) {
          case 'AC':
            acCount++;
            break;
          case 'DC':
            dcCount++;
            break;
          default:
            break;
        }
      });
    }

// Prepare chart data
const chargeTypeData = [
  acCount,
  dcCount
];

console.log("chargeTypes:", chargeTypeData)

    // Extract road percentages 
  const roadPercentages = road === undefined 
  ? [] 
  :road.map(item => ({
    roadisCity: Number(item.roadisCity),
    roadisDN: Number(item.roadisDN), 
    roadisHighway: Number(item.roadisHighway),
    roadisOther: Number(item.roadisOther)
  }));

  // Sum road percentages
  const totals = {
    roadisCity: 0,
    roadisDN: 0, 
    roadisHighway: 0,
    roadisOther: 0
  };

  roadPercentages.forEach(percentages => {
    totals.roadisCity += percentages.roadisCity;
    totals.roadisDN += percentages.roadisDN;
    totals.roadisHighway += percentages.roadisHighway; 
    totals.roadisOther += percentages.roadisOther;
  });

  // Format data for chart
  const chartData = [
    totals.roadisCity,
    totals.roadisDN,
    totals.roadisHighway, 
    totals.roadisOther
  ];

  //Total Intrari in Service
  const TotalNrOfServiceEntries = service === undefined 
  ? '0' 
  : service.length.toString();

  //Total Metrici Inregistrate
  const TotalRegisteredMetrics = metric === undefined 
  ? '0' 
  : metric.length.toString();

  //Suma kilometriilor parcursi
  const SUMKilometersDriven = metric === undefined 
  ? '0' 
  : (metric
      .reduce((acc, item) => acc + item.kmDriven, 0)
      .toFixed(2));
  
  // const kmDrivenValues: string[] = metric === undefined ? []: metric.map((item) => String(item.kmDriven));
  const KilometersDriven = [
    {
      name: "Kilometers Driven",
      data: metric === undefined 
        ? [] 
        : metric
            .sort((a, b) => a.retroDate && b.retroDate ? new Date(a.retroDate).getTime() - new Date(b.retroDate).getTime() : 0)
            .map(item => item.kmDriven),
    }
  ];
  const ConsumMediu = [
    {
      name: "Consum Mediu",
      data: metric === undefined 
        ? [] 
        : metric
            .sort((a, b) => a.retroDate && b.retroDate ? new Date(a.retroDate).getTime() - new Date(b.retroDate).getTime() : 0)
            //.filter(item => (item.retroDate === null || item.retroDate === undefined) )
            .map(item => item.mediumConsumption),
    }
  ];
  // console.log("here", metric);
  //console.log(ConsumMediu);
  const CosturiIncarcari = [
    {
      name: "Cost Încărcare",
      data: cost === undefined 
        ? [] 
        : cost
            .sort((a, b) => a.chargeDate && b.chargeDate ? new Date(a.chargeDate).getTime() - new Date(b.chargeDate).getTime() : 0)
            //.filter(item => (item.retroDate === null || item.retroDate === undefined) )
            .map(item => item.chargeCost),
    }
  ];

    //sorting DateValues for Cost - old
    const CostDateValues: string[] = cost === undefined 
    ? [] 
    : cost
        .sort((a, b) => a.chargeDate && b.chargeDate ? new Date(a.chargeDate).getTime() - new Date(b.chargeDate).getTime() : 0)
        //.filter(item => (item.retroDate === null || item.retroDate === undefined) )
        .map((item) => String(Intl.DateTimeFormat("ro-RO").format(new Date(item?.chargeDate!))));


  //sorting DateValues
  const DateValues: string[] = metric === undefined 
  ? [] 
  : metric
      .sort((a, b) => a.retroDate && b.retroDate ? new Date(a.retroDate).getTime() - new Date(b.retroDate).getTime() : 0)
      .map((item) => String(Intl.DateTimeFormat("en-EN").format(new Date(item?.retroDate!))));

  //sorting DateValuesRO
  const DateValuesRO: string[] = metric === undefined 
  ? [] 
  : metric
      .sort((a, b) => a.retroDate && b.retroDate ? new Date(a.retroDate).getTime() - new Date(b.retroDate).getTime() : 0)
      //.filter(item => (item.retroDate === null || item.retroDate === undefined) )
      .map((item) => String(Intl.DateTimeFormat("ro-RO").format(new Date(item?.retroDate!))));

    // const ConsumMediuRetro = [
    //     {
    //       name: "Consum Mediu",
    //       data: metric === undefined 
    //         ? [] 
    //         : metric
    //             .sort((a, b) => a.retroDate && b.retroDate ? new Date(a.retroDate).getTime() - new Date(b.retroDate).getTime() : 0)
    //             .filter(item => item.freetext && item.freetext.includes("retro") && item.retroDate !== null && item.retroDate !== undefined)
    //             .map(item => item.mediumConsumption),
    //     }
    //   ];


  //sorting DateValuesRO
  // const DateValuesRORetro: string[] = metric === undefined 
  // ? [] 
  // : metric
  //     .sort((a, b) => a.retroDate && b.retroDate ? new Date(a.retroDate).getTime() - new Date(b.retroDate).getTime() : 0)
  //     .filter(item => item.freetext && item.freetext.includes("retro") && item.retroDate !== null && item.retroDate !== undefined)
  //     .map((item) => String(Intl.DateTimeFormat("ro-RO").format(new Date(item?.retroDate!))));      
      
  useEffect(() => {
    async function grabMetric(){
      const allMetrics = await API.graphql<GraphQLQuery<ListMetricsQuery>>({
        query: queries.listMetrics,
      });
      setMetric(allMetrics.data?.listMetrics?.items as MetricsSimpleValuesTable[]);
    }
    grabMetric();
  },[]);

  useEffect(() => {
    async function grabService(){
      const allServices = await API.graphql<GraphQLQuery<ListServiceHistoriesQuery>>({
        query: queries.listServiceHistories,
      });
      setService(allServices.data?.listServiceHistories?.items as ServiceHistoryValues[]);
    }
    grabService();
  },[]);

  useEffect(() => {
    async function grabCost(){
      const allCosts = await API.graphql<GraphQLQuery<ListChargingHistoriesQuery>>({
        query: queries.listChargingHistories,
      });
      setCost(allCosts.data?.listChargingHistories?.items as Incarcare[]);
    }
    grabCost();
  },[]);

  useEffect(() => {
    async function grabRoad(){
      const allRoads = await API.graphql<GraphQLQuery<ListMetricsQuery>>({
        query: queries.listMetrics,
      });
      setRoad(allRoads.data?.listMetrics?.items as BarChartRoadType[]);
    }
    grabRoad();
  },[]);


  useEffect(() => {
    const doChartData = async () => {
      const result = await getChartData();
      setBarChartData(result);
      setTrafficSourceData([112332, 123221, 432334, 342334, 133432]);
    };

    doChartData();
  }, []);

  //new 10.04.204
  useEffect(() => {
    async function grabChargeType(){
      const allChargeTypes = await API.graphql<GraphQLQuery<ListChargingHistoriesQuery>>({
        query: queries.listChargingHistories,
      });
      setChargeType(allChargeTypes.data?.listChargingHistories?.items as ChargingHistoryValues[]);
    }
    grabChargeType();
  },[]);

  useEffect(() => {
    async function grabPlace(){
      const allPlaces = await API.graphql<GraphQLQuery<ListChargingHistoriesQuery>>({
        query: queries.listChargingHistories,
      });
      setPlace(allPlaces.data?.listChargingHistories?.items as ChargingPlaces[]);
    }
    grabPlace();
  },[]);

  useEffect(() => {
    async function grabChargeQuantity(){
      const allgrabChargeQ = await API.graphql<GraphQLQuery<ListChargingHistoriesQuery>>({
        query: queries.listChargingHistories,
      });
      setChargeQuantity(allgrabChargeQ.data?.listChargingHistories?.items as ChargingHistoryValues[]);
    }
    grabChargeQuantity();
  },[]);

  return (
    <>
      <div>
        <h2>Dashboard</h2>
      </div>
      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
        <Grid
          templateColumns={{ base: "1fr", large: "1fr 1fr 1fr" }}
          templateRows={{ base: "repeat(4, 10rem)", large: "repeat(3, 8rem)" }}
          gap={tokens.space.xl}
        >
          <View rowSpan={{ base: 1, large: 1 }}>
            <MiniStatistics
              title="Total Kilometrii Inregistrati"
              amount={SUMKilometersDriven}
              icon={<MdRemoveRedEye />}
            />
          </View>
          <View rowSpan={{ base: 1, large: 1 }}>
            <MiniStatistics title="Intrari In Service" amount={TotalNrOfServiceEntries} icon={<MdCarRepair />} />
          </View>
          <View rowSpan={{ base: 1, large: 1 }}>
            <MiniStatistics
              title="Total Metrici Introduse"
              amount={TotalRegisteredMetrics}
              icon={<MdEditRoad />}
            />
          </View>

          <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
            <Card borderRadius="15px">
              <div className="card-title">Kilometrii Parcursi</div>
              <div className="chart-wrap">
                {barChartData ? (
                  <div className="row">
                    {  
                    <TrafficSummary
                      title="Traffic Summary"
                      data={KilometersDriven}
                      type="bar"
                      labels={DateValues}
                    />
                    }
                  </div>
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>
          <View rowSpan={{ base: 1, large: 4 }}>
            <Card height="100%" borderRadius="15px">
              <div className="card-title">Tip Sosea</div>
              <div className="chart-wrap">
                {trafficSourceData ? (
                  <TrafficSources
                    title="Traffic Sources"
                    data={chartData}
                    type="donut"
                    labels={[
                      "Oras",
                      "Drum National",
                      "Autostrada",
                      "Altele",
                    ]}
                  />
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>

          <View columnSpan={[1, 1, 1, 3]} rowSpan={{ base: 3, large: 4 }}>
            <Card borderRadius="15px">
              <div className="card-title">Consum Mediu</div>
              <div className="chart-wrap">
                {barChartData ? (
                  <div className="row">
                    <SalesSummary
                      title="Sales Summary"
                      data={ConsumMediu}
                      type="line"
                      labels={DateValuesRO}
                    />
                  </div>
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>

          <View rowSpan={{ base: 1, large: 4 }}>
            <Card height="100%" borderRadius="6px">
              <div className="card-title">Încărcări</div>
              <div className="chart-wrap">
              <MiniStatistics
              title="Număr de Încărcări Înregistrate"
              amount={TotalRegisteredCharges}
              icon={<MdEvStation size={"large"}/>}
            />
              </div>
            </Card>
          </View>          

          <View rowSpan={{ base: 1, large: 4 }}>
            <Card height="100%" borderRadius="6px">
              <div className="card-title">Locuri de Încărcare</div>
              <div className="chart-wrap">
                {trafficSourceData ? (
                  <TrafficSources
                    title="Traffic Sources"
                    data={locationData}
                    type="donut"
                    options
                    labels={[
                      "Acasa",
                      "Locul De Munca",
                      "Retea Publica",
                      "Altele (hotel, restaurant, etc.)",
                    ]}
                  />
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>

          <View rowSpan={{ base: 1, large: 4 }}>
            <Card height="100%" borderRadius="6px">
              <div className="card-title">Tip Încărcare</div>
              <div className="chart-wrap">
                {trafficSourceData ? (
                  <TrafficSources
                    title="Traffic Sources"
                    data={chargeTypeData}
                    type="pie"
                    labels={[
                      "AC",
                      "DC",
                    ]}
                  />
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>


          <View columnSpan={[1, 1, 1, 3]} rowSpan={{ base: 3, large: 4 }}>
            <Card height="100%" borderRadius="15px">
              <div className="card-title">Costuri Încărcări</div>
              <div className="chart-wrap">
                {barChartData ? (
                  <div className="row">
                    <CustomersSummary
                      title="Cost"
                      data={CosturiIncarcari}
                      type="area"
                      labels={CostDateValues}
                    />
                  </div>
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>

          <View columnSpan={[1, 1, 1, 3]} rowSpan={{ base: 3, large: 4 }}>
            <Card borderRadius="15px">
              <div className="card-title">Încărcări (kWh)</div>
              <div className="chart-wrap">
                {barChartData ? (
                  <div className="row">
                    {  
                    <TrafficSummary
                      title="Cantitate (kWh)"
                      data={chargeQuantityData}
                      type="bar"
                      labels={chargeQuantityDates}
                    />
                    }
                  </div>
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>
                     

          {/* Display if ConsumMediuRetro has at least two values inside */}
          {/* {
          ConsumMediuRetro && ConsumMediuRetro[0] && ConsumMediuRetro[0].data && ConsumMediuRetro[0].data.length >= 2 && DateValuesRORetro && (
          <View columnSpan={[1, 1, 1, 2]} rowSpan={{ base: 3, large: 4 }}>
            <Card borderRadius="15px">
              <div className="card-title">Consum Mediu Retroactiv</div>
              <div className="chart-wrap">
                {barChartData ? (
                  <div className="row">
                    <SalesSummary
                      title="Sales Summary"
                      data={ConsumMediuRetro}
                      type="line"
                      labels={DateValuesRORetro}
                    />
                  </div>
                ) : (
                  <Flex direction="column" minHeight="285px">
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                    <Placeholder size="small" />
                  </Flex>
                )}
              </div>
            </Card>
          </View>
          )} */}
        </Grid>
      </View>
    </>
  );
};

export default Dashboard;