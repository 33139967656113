import React from "react";
import { 
  Text,
  Button
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
// import EVCreateForm from "../../ui-components/EVCreateForm";
//table
import { GraphQLQuery } from "@aws-amplify/api";
import { useEffect, useState } from "react";
import * as queries from "../../graphql/queries";
import { ListEVSQuery } from "@/API";
import { API } from "aws-amplify";
import DisplayEVSTable from "../../pages/datatables/EV/DisplayEVSTable";
import { EVValues } from "@/data/fetchValues";



const ProfileEV = () => {
  const navigate = useNavigate();
  const [ev, setEV] = useState<EVValues[]>();
  useEffect(() => {
    async function grabEV() {
      const allEVS = await API.graphql<GraphQLQuery<ListEVSQuery>>({
        query: queries.listEVS,
      });
      setEV(allEVS.data?.listEVS?.items as EVValues[]);
    }
    grabEV();
  }, []);

  return (
    <div className="profile-card-content">
      <Text fontWeight="600" fontSize="18px" marginBottom="14px">
        Registered EV's:
      </Text>
      <div className="profile">
      {ev === undefined ? null : (
          <DisplayEVSTable tableName=" - " data={ev}/> 
        )}

        <Button variation={"primary"} marginLeft="auto" onClick={() => navigate("/addev") }>Adauga o masina noua</Button>
        </div>
    </div>
    
  );
};

export default ProfileEV;
