import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { ThemeProvider } from "@aws-amplify/ui-react";
import theme from "./theme";

import Layout from "./components/Layout";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Tables from "./pages/tables";
import UsersTable from "./pages/tables/UsersTablePage";
import Forms from "./pages/forms";
import EditForm from "./pages/forms/EditForm";
import AddEVForm from "./pages/addev";
import AddModelForm from "./pages/addmodel";
import AddReportForm from "./pages/addreport";
import AddRetroMetricForm from "./pages/addretro";
import UpdateMetricForm from "./pages/updatereport";
import DisplayMetricsTable from "./pages/datatables";
import AddServiceHistory from "./pages/addservicehistory/AddServiceHistoryForm";
import AddChargingHistory from "./pages/addcharginghistory/AddChargingHistoryForm";
import ServiceHistoryTable from "./pages/datatables/ServiceHistory";
import UpdateServiceHistoryForm from "./pages/updateServiceHistory";
import ChargingHistoryTable from "./pages/datatables/ChargingHistory";
import UpdateChargingHistoryForm from "./pages/updateChargingHistory";
import About from "./pages/about";
//Welcome
import WelcomePage from "./pages/welcome";
import GDPR from "./pages/welcome/GdprPage";
import GDPRExist from "./pages/welcome/GdprPageUserExists";
import AddCarPage from "./pages/welcome/AddCarPage";





//auth imports
import {
  withAuthenticator
} from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
//import MetricsTable from "./pages/datatables";

Amplify.configure(awsExports);
  function App() {


  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="forms" element={<Forms />} />
            <Route path="edit-form" element={<EditForm />} />
            <Route path="tables" element={<Tables />} />
            <Route path="users-table" element={<UsersTable />} />
            <Route path="profile" element={<Profile />} />
            <Route path="addev" element={<AddEVForm />} />
            <Route path="addmodel" element={<AddModelForm />} />
            <Route path="addreport" element={<AddReportForm />} />
            <Route path="updatereport/:id" element={<UpdateMetricForm />} />
            <Route path="updateservice/:id" element={<UpdateServiceHistoryForm />} />
            <Route path="updatecharging/:id" element={<UpdateChargingHistoryForm />} />
            <Route path="addretro" element={<AddRetroMetricForm />} />
            <Route path="display-user-metrics" element={<DisplayMetricsTable />} />
            <Route path="addservice" element={<AddServiceHistory />} />
            <Route path="addcharging" element={<AddChargingHistory />} />
            <Route path="display-user-servicehistory" element={<ServiceHistoryTable />} />
            <Route path="display-user-charginghistory" element={<ChargingHistoryTable />} />
            <Route path="about" element={<About />} />
            {/*welcome*/}
            <Route path="welcome" element={<WelcomePage />} />
            <Route path="gdpr" element={<GDPR />} />
            <Route path="gdprupdate" element={<GDPRExist />} />
            <Route path="addcar" element={<AddCarPage />} />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}