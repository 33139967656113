import React from "react";
import { View, Heading, Text, Button, Flex } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";


const AddCarPage = () => {
    const navigate = useNavigate();
    // const handleBack = () => {
    //     navigate("/gdpr");
    //   };
    
  return (
    <>
    <div>
    <br></br>
    </div>
    <View
      backgroundColor="var(--amplify-colors-white)"
      borderRadius="6px"
      maxWidth={{ base: "100%", large: "100%" }}
      padding="1rem"
      minHeight="100vh"
    >
      <View textAlign="center" >
        <Heading color="#333"> Super - am ajuns la final ! </Heading>
        </View>
        <br></br>
      <View textAlign="center" >
      <Text>

        <br />
        Ca să putem "porni la drum" - ne trebuie o mașină inregistrată prima și prima dată.
        <br />
        Introduceți vă rugăm cateva detalii scurte non-sensitive despre mașina dumneavoastră electrică pentru a o
        adăuga în aplicație. 
        <br />
        Pe masură ce creștem și noi veți putea apoi monitoriza consumul, performanța și
        alte statistici importante.
      </Text>
      </View>
      <br />
      <Flex
        direction="row"
        wrap="nowrap"
        gap="1rem"
        justifyContent="center"
      >
      {/* <Button onClick={handleBack} >
          Înapoi
      </Button> */}

      <Button onClick={() => navigate("/addev")}>Adaugă mașina acum!</Button>
      <img
                style={{ objectFit: "scale-down", width: "75px", height: "50px" }}
                src={require("./AddCar.png")}
                alt="gdpr"
              >
        </img>
      </Flex>
      
    </View>
    </> 
  );
};

export default AddCarPage;