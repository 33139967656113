import React from "react";
import {
  View
} from "@aws-amplify/ui-react";
import EVCreateForm from "../../ui-components/EVCreateForm";



const BasicForm = () => {
  return (
    <>
          <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <EVCreateForm />
      </View>
    </>
  );
};

export default BasicForm;
