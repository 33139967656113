import React from "react";
import { View, Heading } from "@aws-amplify/ui-react";
import AddRetroMetricForm from "./AddRetroMetricForm";
import { MdDangerous } from "react-icons/md";

const Reports = () => {
  return (
    <>
      <div>
        <h2>Date din Trecut</h2>
      </div>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <Heading color="#333"> Adauga Raportare Retroactiva </Heading>
        <div> <MdDangerous size={"30px"}/>   Va rugam nu introduceti date aici decat daca vi s-a comunicat. Multumim !</div>
        <AddRetroMetricForm />
      </View>
    </>
  );
};

export default Reports;
