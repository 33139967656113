import React from "react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";

import { Auth } from '@aws-amplify/auth';

//table imports:
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { EVValues } from "@/data/fetchValues";
import { Button } from "@aws-amplify/ui-react";
import ImportData from '../../../bulk';
interface ItemsTableProps {
  tableName: string;
  data: EVValues[];
}

function getImportData(email: string) {
  const data = require(`../../../bulk/${email}.json`);  // assuming data is stored in json files named by email
  return data; 
}

export default function DisplayEVSTable({ tableName, data }: ItemsTableProps) {

  const [userEmail, setUserEmail] = React.useState<string>("");

  // Define a list of email addresses which are allowed to import data
  const allowedEmails = [ 
      'shjaeaksxhzhrfysun@cazlp.com', 
      'mirciulicah@gmail.com', 
      'adamtmircea@gmail.com',
      'tudormarchis95@gmail.com',
      // ... add more emails here
  ];
  
  React.useEffect(() => {
      (async () => {
          const user = await Auth.currentAuthenticatedUser();
          setUserEmail(user.attributes.email);
      })();    
  }, []);

  return (
    <Table caption={tableName}>
      <TableHead>
        <TableRow>
          <TableCell as="th">Data Adaugarii</TableCell>
          <TableCell as="th">Status</TableCell>
          <TableCell as="th">Date Retroactive</TableCell>
          <TableCell as="th">ID Electromobilitate</TableCell>
          <TableCell as="th"></TableCell>
          <TableCell as="th">Actiuni</TableCell>                  
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, key) => (
          <TableRow key={key}>
            <TableCell>
              {Intl.DateTimeFormat("en-us").format(new Date(item?.createdAt!))}
            </TableCell>
            <TableCell>{
            item?.status === true ?  
            <Button variation={"primary"} onClick={() => alert("Masina dvs. trimite metrici")} size="small">ACTIV</Button> 
            : 
            <Button variation={"warning"} onClick={() => alert("Masina dvs. NU trimite metrici")} size="small">INACTIV</Button>
            }</TableCell>
            <TableCell>{
                  <Button
                      isDisabled={!allowedEmails.includes(userEmail)}  
                      variation={"warning"} 
                      marginLeft="auto" 
                      onClick={() => {
                          console.log("Import Requested.");
                          // Again assuming you have a method getImportData fetching data based on email
                          const userSampleData = getImportData(userEmail); 
                          ImportData(userSampleData);
                      }}> 
                      Adaugă
                  </Button>
                    
                    }
          </TableCell>
            <TableCell>{item?.id}</TableCell>
            <TableCell >{
                            item?.photo === null || item?.photo === undefined ? null :(
                            <StorageImage
                                width={"18rem"}
                                accessLevel="private"
                                alt="image"
                                imgKey={item?.photo}>
                                </StorageImage>
                            )}</TableCell>
            <TableCell as="th">
            <Menu
        menuAlign="end"
        trigger={
          
          <MenuButton variation="link" size="small" color={"HighlightText"}>...
          </MenuButton>
        }
      >
        <MenuItem size="small" onClick={() => alert("Sorry, not available yet")} >EDIT</MenuItem>
        <MenuItem size="small" variation="destructive" onClick={() => alert("Sorry, not available yet")} color={"red.40"}>DELETE</MenuItem>
      </Menu>
              
              
              </TableCell>                              
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}