import React from "react";
import { View, Heading, Text, Button, Flex } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/welcome");
  };

  return (
    <>
    <div>
    <br></br>
    </div>
    <View
      backgroundColor="var(--amplify-colors-white)"
      borderRadius="6px"
      maxWidth={{ base: "100%", large: "100%" }}
      padding="1rem"
      minHeight="100vh"
      className="welcome-page"
    >
      <View textAlign="center" >
        <Heading color="#333"> Bine ați venit ! </Heading>
        <br></br>
        <img
                style={{ objectFit: "scale-down", width: "800px", height: "300px" }}
                src={require("./welcome.png")}
                alt="ExempluModel"
              >
        </img>
        </View>
        <View textAlign="center" >  
      <Text>
        Aplicația Electromobilitate vă ajută să automatizați colectarea și
        procesarea datelor/metricilor pentru a promova mobilitatea electrică și
        pentru a obține statistici ample.
      </Text>
      <br></br><br></br>
      </View>
      <Heading color="#333" as="h2">Informații importante:</Heading>

      <ul>
        <li>
          Aplicația se află înca în faza de "Early Access" de la 20 decembrie
          2023 și este disponibilă doar pe varianta web pentru un grup restrâns
          de utilizatori.
        </li>
        <li>
          Vă mulțumim pentru răbdare, interes și feedback!
        </li>
      </ul>
      
      <br></br>
      <Flex
        direction="row"
        wrap="nowrap"
        gap="1rem"
        justifyContent="space-between"
      >
      <Button onClick={handleBack} >
          Înapoi
      </Button>
      <Button onClick={() => navigate("/addcar")}>Continuă</Button>
      </Flex>
    </View>
    </>
  );
};

export default WelcomePage;