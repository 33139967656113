import React, { useEffect, useState } from "react";
import { View } from "@aws-amplify/ui-react";
import ModelCreateForm, { ModelCreateFormInputValues } from "../../ui-components/ModelCreateForm";
import { useNavigate } from "react-router-dom";
import * as queries from "../../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api";
import { ListModelsQuery } from "@/API";
import { GetModelValues } from "@/data/fetchValues";
import { API } from "aws-amplify";

const AddModel = () => {
  const navigate = useNavigate();
  
  const handleSubmit = (values: ModelCreateFormInputValues): ModelCreateFormInputValues => {
    alert('Submitted successfully');
    navigate('/profile');
    return values;
  };

  const [handleModel, sethandleModel] = useState<GetModelValues[]>();
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    async function grabhandleModel(){
      const allModels = await API.graphql<GraphQLQuery<ListModelsQuery>>({
        query: queries.listModels,
      });
      sethandleModel(allModels.data?.listModels?.items as GetModelValues[]);
    }
    grabhandleModel();
  },[]);
  
  const handleFormChange = (values: ModelCreateFormInputValues): ModelCreateFormInputValues => { 
    const hasDuplicates = handleModel && handleModel.some(model =>
      (model.brand ? model.brand.toLowerCase() : '') === (values.brand ? values.brand.toLowerCase() : '') &&
      (model.modelName ? model.modelName.toLowerCase() : '') === (values.modelName ? values.modelName.toLowerCase() : '') &&
      (model.modelType ? model.modelType.toLowerCase() : '') === (values.modelType ? values.modelType.toLowerCase() : '') &&
      model.yearOfProduction === values.yearOfProduction &&
      model.batteryCapacity === values.batteryCapacity
    );
  
    if (hasDuplicates) {
      alert('This model already exists');
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
    
    return values;
  };

  return (
    <>
          <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
<ModelCreateForm 
  onChange={handleFormChange}
  onSubmit={handleSubmit}
  onError={() => alert("Ne pare rau, a aparut o Eroare. Verificati daca nu cumva ati introdus caractere speciale in campurile de mai jos sau daca modelul introdus exista deja in baza noastra de date")}
  overrides={{
    categoryID: {
      defaultValue: "Fara Categorie", 
      onChange: () => {}, 
      onBlur: () => {}, 
    },
    "SubmitButton": {
        isDisabled: !isFormValid
    },
  }}
/>
      </View>
    </>
  );
};

export default AddModel;