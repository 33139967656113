import React from "react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";

import { useNavigate } from "react-router-dom";

//table imports:
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { MetricsSimpleValuesTable } from "@/data/fetchValues";
// First, import the deleteMetric mutation
import * as mutations from "../../graphql/mutations";
import { API } from "aws-amplify";
import { useState } from "react";
import { 
  MdModeEdit,
  MdDriveFileRenameOutline,
  MdDelete 
} from "react-icons/md";


interface ItemsTableProps {
  tableName: string;
  data: MetricsSimpleValuesTable[];
}

export default function DisplayMetricsTable({ tableName, data }: ItemsTableProps) {
  const navigate = useNavigate();
  // Sort data by createdAt
  const sortedData = [...data].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    // The deleteMetric function could look something like this:
    const [metric, setMetric] = useState<MetricsSimpleValuesTable[]>();  
    const deleteMetric = async (id: string) => {
      // Confirm with the user
    const confirm = window.confirm("Sunteti sigur/a ca doriti sa stergeti aceast raport ?");
    if (!confirm) return;  // If the user clicks "Cancel", stop here
      try {
        const metricToDelete = {
          id, // Id of the metric you want to delete
        };
  
        await API.graphql({
          query: mutations.deleteMetric, 
          variables: { input: metricToDelete } 
        });
  
        // Then you can update your local state to remove the deleted metric
        if (metric) {
          setMetric(metric.filter(item => item.id !== id));
        }
        // After deletion, navigate and refresh the page:
        // window.location.href = "/display-user-metrics";
        navigate('/');
      } catch (error) {
        console.log("Error deleting metric:", error);
      }
    };
  return (
    <Table caption={tableName}>
      <TableHead>
        <TableRow>
          <TableCell as="th">Data Adaugarii</TableCell>
          <TableCell as="th">Retro Date</TableCell>
          <TableCell as="th">Tip Raport</TableCell>
          <TableCell as="th">Kilometrii Parcursi (km)</TableCell>
          <TableCell as="th">Cosum Mediu (kWh)</TableCell>
          <TableCell as="th">Viteza Medie (km/h)</TableCell>
          <TableCell as="th">Domeniul de Temperatura (C)</TableCell>
          <TableCell as="th">Poza Bord</TableCell>
          <TableCell as="th">Actiuni</TableCell>                   
        </TableRow>
      </TableHead>
      <TableBody>
        {
        sortedData.map((item, key) => (
          <TableRow key={key}>
            <TableCell>
              {Intl.DateTimeFormat("en-us").format(new Date(item?.createdAt!))}
            </TableCell>
            <TableCell>{item?.retroDate}</TableCell>
            <TableCell>{item?.reportType}</TableCell>
            <TableCell>{item?.kmDriven}</TableCell>
            <TableCell>{item?.mediumConsumption}</TableCell>
            <TableCell>{item?.avgTravelSpeed}</TableCell>
            <TableCell>{item?.temp}</TableCell>
            <TableCell >{
                            item?.photo === null || item?.photo === undefined ? null :(
                            <StorageImage
                                width={"5rem"}
                                accessLevel="private"
                                alt="image"
                                imgKey={item?.photo}>
                                </StorageImage>
                            )}</TableCell>
            <TableCell as="th">
            <Menu
        menuAlign="end"
        trigger={
          
          <MenuButton variation="primary" size="small" color={"ButtonHighlight"}>
            <MdModeEdit />
          </MenuButton>
          

        }
      >
        <MenuItem size="small" onClick={() => navigate(`/updatereport/${item.id}`)} > <MdDriveFileRenameOutline /> EDIT </MenuItem>
        <MenuItem 
          size="small" 
          variation="destructive" 
          color={"red.40"} 
          onClick={() => deleteMetric(item.id)}  // Call deleteMetric with the id of the item
        >
          <MdDelete /> DELETE
        </MenuItem>
      </Menu>
              
              
              </TableCell>                              
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}