import React from "react";
import { Text, SwitchField } from "@aws-amplify/ui-react";

const ProfileSettings = () => {
  return (
    <div className="profile-card-content">
      <Text fontWeight="600" fontSize="18px" marginBottom="18px">
        Profile Settings (not available yet)
      </Text>

      <SwitchField
        isDisabled={false}
        label="Subscribe to newsletter"
        labelPosition="end"
        defaultChecked={false}
      />

      <SwitchField
        isDisabled={false}
        label="Reminder pe email pentru trimis raport săptămânal."
        labelPosition="end"
        defaultChecked={true}
      />
    </div>
  );
};

export default ProfileSettings;
