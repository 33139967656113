import React from "react";
import {
  View
} from "@aws-amplify/ui-react";
import MetricCreateForm from "../../ui-components/MetricCreateForm";
import { useNavigate } from "react-router-dom";


const AddMetricForm = () => {
  const navigate = useNavigate();
  const handleSuccess = () => {
    alert('Multumim. Adaugat cu Succes.');
    navigate('/display-user-metrics');
  };
  return (
    <>
          <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <MetricCreateForm 
        onSuccess={handleSuccess} 
        onError={() => alert("Ne pare rau a aparut o Eroare. Daca eroarea persista va rugam sa ne spuneti")} 
        />
      </View>
    </>
  );
};

export default AddMetricForm;
