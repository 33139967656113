import React from "react";
import { Heading, View, Text, Link } from "@aws-amplify/ui-react";
import BasicAdd from "./AddModel";
import { MdInfo } from "react-icons/md";

const AddModelForm = () => {

  return (
    <>
      <div>
        <br></br>
      </div>
        <br></br>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
      <View textAlign="center" >
        <Heading color="#333"> ADAUGARE MODEL NOU DE EV </Heading>
        </View>
        <Text> 
        <br></br><br></br>  
        <MdInfo size={"20px"} /> Nu avem modelul dumneavoastra de masina ?
        <br></br>Nicio problema - puteti sa il adaugati aici.<br></br> 
        </Text>
            <img
                style={{ objectFit: "scale-down", width: "800px", height: "180px" }}
                src={require("./ExempluModel.png")}
                alt="ExempluModel"
              >
            </img>
        <Text>      
          <br></br> Va rugam: <br></br>
          - Completati campurile de mai jos. Toate sunt obligatorii. <br></br>
          - <span style={{ color: "red" }}>Selectati "Fara Categorie"</span> daca nu stiti categoria, mai sus exemplu..
          <br></br>
          <Link href="https://evdatabase.org/" target="_blank" rel="noopener noreferrer">
          - Pentru inspiratie si detalii folositi acest link.<br></br>
          </Link>
          Multumim!
        </Text>             
        <BasicAdd /> 
      </View>
    </>
  );
};

export default AddModelForm;