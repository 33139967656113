/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SelectField,
  SliderField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import {
  fetchByPath,
  getOverrideProps,
  processFile,
  validateField,
} from "./utils";
import {
  eVMetricsByMetricId,
  getMetric,
  listEVMetrics,
  listEVS,
} from "../graphql/queries";
import { API } from "aws-amplify";
import {
  createEVMetric,
  deleteEVMetric,
  updateMetric,
} from "../graphql/mutations";
import { Field } from "@aws-amplify/ui-react/internal";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function MetricUpdateForm(props) {
  const {
    id: idProp,
    metric: metricModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    reportType: "",
    retroDate: "",
    kmDriven: "",
    avgTravelSpeed: "",
    mediumConsumption: "",
    roadisCity: 0,
    roadisDN: 0,
    roadisHighway: 0,
    roadisOther: 0,
    passengers: "",
    useCase: undefined,
    temp: "",
    evs: [],
    photo: undefined,
    freetext: "",
  };
  const [reportType, setReportType] = React.useState(initialValues.reportType);
  const [retroDate, setRetroDate] = React.useState(initialValues.retroDate);
  const [kmDriven, setKmDriven] = React.useState(initialValues.kmDriven);
  const [avgTravelSpeed, setAvgTravelSpeed] = React.useState(
    initialValues.avgTravelSpeed
  );
  const [mediumConsumption, setMediumConsumption] = React.useState(
    initialValues.mediumConsumption
  );
  const [roadisCity, setRoadisCity] = React.useState(initialValues.roadisCity);
  const [roadisDN, setRoadisDN] = React.useState(initialValues.roadisDN);
  const [roadisHighway, setRoadisHighway] = React.useState(
    initialValues.roadisHighway
  );
  const [roadisOther, setRoadisOther] = React.useState(
    initialValues.roadisOther
  );
  const [passengers, setPassengers] = React.useState(initialValues.passengers);
  const [useCase, setUseCase] = React.useState(initialValues.useCase);
  const [temp, setTemp] = React.useState(initialValues.temp);
  const [evs, setEvs] = React.useState(initialValues.evs);
  const [evsLoading, setEvsLoading] = React.useState(false);
  const [evsRecords, setEvsRecords] = React.useState([]);
  const [photo, setPhoto] = React.useState(initialValues.photo);
  const [freetext, setFreetext] = React.useState(initialValues.freetext);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = metricRecord
      ? { ...initialValues, ...metricRecord, evs: linkedEvs }
      : initialValues;
    setReportType(cleanValues.reportType);
    setRetroDate(cleanValues.retroDate);
    setKmDriven(cleanValues.kmDriven);
    setAvgTravelSpeed(cleanValues.avgTravelSpeed);
    setMediumConsumption(cleanValues.mediumConsumption);
    setRoadisCity(cleanValues.roadisCity);
    setRoadisDN(cleanValues.roadisDN);
    setRoadisHighway(cleanValues.roadisHighway);
    setRoadisOther(cleanValues.roadisOther);
    setPassengers(cleanValues.passengers);
    setUseCase(cleanValues.useCase);
    setTemp(cleanValues.temp);
    setEvs(cleanValues.evs ?? []);
    setCurrentEvsValue(undefined);
    setCurrentEvsDisplayValue("");
    setPhoto(cleanValues.photo);
    setFreetext(cleanValues.freetext);
    setErrors({});
  };
  const [metricRecord, setMetricRecord] = React.useState(metricModelProp);
  const [linkedEvs, setLinkedEvs] = React.useState([]);
  const canUnlinkEvs = false;
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await API.graphql({
              query: getMetric.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getMetric
        : metricModelProp;
      const linkedEvs = record
        ? (
            await API.graphql({
              query: eVMetricsByMetricId.replaceAll("__typename", ""),
              variables: {
                metricId: record.id,
              },
            })
          ).data.eVMetricsByMetricId.items.map((t) => t.ev)
        : [];
      setLinkedEvs(linkedEvs);
      setMetricRecord(record);
    };
    queryData();
  }, [idProp, metricModelProp]);
  React.useEffect(resetStateValues, [metricRecord, linkedEvs]);
  const [currentEvsDisplayValue, setCurrentEvsDisplayValue] =
    React.useState("");
  const [currentEvsValue, setCurrentEvsValue] = React.useState(undefined);
  const evsRef = React.createRef();
  const getIDValue = {
    evs: (r) => JSON.stringify({ id: r?.id }),
  };
  const evsIdSet = new Set(
    Array.isArray(evs)
      ? evs.map((r) => getIDValue.evs?.(r))
      : getIDValue.evs?.(evs)
  );
  const getDisplayValue = {
    evs: (r) => `${r?.friendlyName ? r?.friendlyName + " - " : ""}${r?.id}`,
  };
  const validations = {
    reportType: [{ type: "Required" }],
    retroDate: [{ type: "Required" }],
    kmDriven: [
      { type: "Required" },
      {
        type: "GreaterThanNum",
        numValues: [-1],
        validationMessage: "The value must be greater than -1",
      },
    ],
    avgTravelSpeed: [
      {
        type: "GreaterThanNum",
        numValues: [-1],
        validationMessage: "The value must be greater than -1",
      },
    ],
    mediumConsumption: [
      { type: "Required" },
      {
        type: "LessThanNum",
        numValues: [60],
        validationMessage: "The value must be less than 60",
      },
      {
        type: "GreaterThanNum",
        numValues: [3],
        validationMessage: "The value must be greater than 3",
      },
    ],
    roadisCity: [
      {
        type: "GreaterThanNum",
        numValues: [-1],
        validationMessage: "The value must be greater than -1",
      },
    ],
    roadisDN: [],
    roadisHighway: [
      {
        type: "GreaterThanNum",
        numValues: [-1],
        validationMessage: "The value must be greater than -1",
      },
    ],
    roadisOther: [
      {
        type: "GreaterThanNum",
        numValues: [-1],
        validationMessage: "The value must be greater than -1",
      },
    ],
    passengers: [
      {
        type: "GreaterThanNum",
        numValues: [-1],
        validationMessage: "The value must be greater than -1",
      },
    ],
    useCase: [],
    temp: [
      {
        type: "LessThanNum",
        numValues: [60],
        validationMessage: "The value must be less than 60",
      },
      {
        type: "GreaterThanNum",
        numValues: [-50],
        validationMessage: "The value must be greater than -50",
      },
    ],
    evs: [],
    photo: [],
    freetext: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchEvsRecords = async (value) => {
    setEvsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { friendlyName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await API.graphql({
          query: listEVS.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listEVS?.items;
      var loaded = result.filter(
        (item) => !evsIdSet.has(getIDValue.evs?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setEvsRecords(newOptions.slice(0, autocompleteLength));
    setEvsLoading(false);
  };
  React.useEffect(() => {
    fetchEvsRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          reportType,
          retroDate,
          kmDriven,
          avgTravelSpeed: avgTravelSpeed ?? null,
          mediumConsumption,
          roadisCity: roadisCity ?? null,
          roadisDN: roadisDN ?? null,
          roadisHighway: roadisHighway ?? null,
          roadisOther: roadisOther ?? null,
          passengers: passengers ?? null,
          useCase: useCase ?? null,
          temp: temp ?? null,
          evs: evs ?? null,
          photo: photo ?? null,
          freetext: freetext ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const promises = [];
          const evsToLinkMap = new Map();
          const evsToUnLinkMap = new Map();
          const evsMap = new Map();
          const linkedEvsMap = new Map();
          evs.forEach((r) => {
            const count = evsMap.get(getIDValue.evs?.(r));
            const newCount = count ? count + 1 : 1;
            evsMap.set(getIDValue.evs?.(r), newCount);
          });
          linkedEvs.forEach((r) => {
            const count = linkedEvsMap.get(getIDValue.evs?.(r));
            const newCount = count ? count + 1 : 1;
            linkedEvsMap.set(getIDValue.evs?.(r), newCount);
          });
          linkedEvsMap.forEach((count, id) => {
            const newCount = evsMap.get(id);
            if (newCount) {
              const diffCount = count - newCount;
              if (diffCount > 0) {
                evsToUnLinkMap.set(id, diffCount);
              }
            } else {
              evsToUnLinkMap.set(id, count);
            }
          });
          evsMap.forEach((count, id) => {
            const originalCount = linkedEvsMap.get(id);
            if (originalCount) {
              const diffCount = count - originalCount;
              if (diffCount > 0) {
                evsToLinkMap.set(id, diffCount);
              }
            } else {
              evsToLinkMap.set(id, count);
            }
          });
          evsToUnLinkMap.forEach(async (count, id) => {
            const recordKeys = JSON.parse(id);
            const eVMetricRecords = (
              await API.graphql({
                query: listEVMetrics.replaceAll("__typename", ""),
                variables: {
                  filter: {
                    and: [
                      { eVId: { eq: recordKeys.id } },
                      { metricId: { eq: metricRecord.id } },
                    ],
                  },
                },
              })
            )?.data?.listEVMetrics?.items;
            for (let i = 0; i < count; i++) {
              promises.push(
                API.graphql({
                  query: deleteEVMetric.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      id: eVMetricRecords[i].id,
                    },
                  },
                })
              );
            }
          });
          evsToLinkMap.forEach((count, id) => {
            const eVToLink = evsRecords.find((r) =>
              Object.entries(JSON.parse(id)).every(
                ([key, value]) => r[key] === value
              )
            );
            for (let i = count; i > 0; i--) {
              promises.push(
                API.graphql({
                  query: createEVMetric.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      metricId: metricRecord.id,
                      eVId: eVToLink.id,
                    },
                  },
                })
              );
            }
          });
          const modelFieldsToSave = {
            reportType: modelFields.reportType,
            retroDate: modelFields.retroDate,
            kmDriven: modelFields.kmDriven,
            avgTravelSpeed: modelFields.avgTravelSpeed ?? null,
            mediumConsumption: modelFields.mediumConsumption,
            roadisCity: modelFields.roadisCity ?? null,
            roadisDN: modelFields.roadisDN ?? null,
            roadisHighway: modelFields.roadisHighway ?? null,
            roadisOther: modelFields.roadisOther ?? null,
            passengers: modelFields.passengers ?? null,
            useCase: modelFields.useCase ?? null,
            temp: modelFields.temp ?? null,
            photo: modelFields.photo ?? null,
            freetext: modelFields.freetext ?? null,
          };
          promises.push(
            API.graphql({
              query: updateMetric.replaceAll("__typename", ""),
              variables: {
                input: {
                  id: metricRecord.id,
                  ...modelFieldsToSave,
                },
              },
            })
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "MetricUpdateForm")}
      {...rest}
    >
      <SelectField
        label="Tipul de raport"
        placeholder="Selectați aici vă rugăm tipul de raport"
        isDisabled={false}
        value={reportType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType: value,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.reportType ?? value;
          }
          if (errors.reportType?.hasError) {
            runValidationTasks("reportType", value);
          }
          setReportType(value);
        }}
        onBlur={() => runValidationTasks("reportType", reportType)}
        errorMessage={errors.reportType?.errorMessage}
        hasError={errors.reportType?.hasError}
        {...getOverrideProps(overrides, "reportType")}
      >
        <option
          children="Săptămânal"
          value="Săptămânal"
          {...getOverrideProps(overrides, "reportTypeoption0")}
        ></option>
        <option
          children="Lunar"
          value="Lunar"
          {...getOverrideProps(overrides, "reportTypeoption1")}
        ></option>
        <option
          children="Zilnic"
          value="Zilnic"
          {...getOverrideProps(overrides, "reportTypeoption2")}
        ></option>
      </SelectField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Update la Data Raportului</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        type="date"
        value={retroDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate: value,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.retroDate ?? value;
          }
          if (errors.retroDate?.hasError) {
            runValidationTasks("retroDate", value);
          }
          setRetroDate(value);
        }}
        onBlur={() => runValidationTasks("retroDate", retroDate)}
        errorMessage={errors.retroDate?.errorMessage}
        hasError={errors.retroDate?.hasError}
        {...getOverrideProps(overrides, "retroDate")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Kilometrii parcurși [km]:</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        descriptiveText="Vă rugăm introduceți kilometrii parcurși. Introduceți doar cifra."
        isRequired={true}
        isReadOnly={false}
        placeholder="Exemplu: 120"
        type="number"
        step="any"
        value={kmDriven}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven: value,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.kmDriven ?? value;
          }
          if (errors.kmDriven?.hasError) {
            runValidationTasks("kmDriven", value);
          }
          setKmDriven(value);
        }}
        onBlur={() => runValidationTasks("kmDriven", kmDriven)}
        errorMessage={errors.kmDriven?.errorMessage}
        hasError={errors.kmDriven?.hasError}
        {...getOverrideProps(overrides, "kmDriven")}
      ></TextField>
      <TextField
        label="Viteza Medie [km/h]:"
        descriptiveText="Vă rugăm introduceți Viteza Medie înregistrată. Introduceți doar cifra."
        isRequired={false}
        isReadOnly={false}
        placeholder="Exemplu: 53.2"
        type="number"
        step="any"
        value={avgTravelSpeed}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed: value,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.avgTravelSpeed ?? value;
          }
          if (errors.avgTravelSpeed?.hasError) {
            runValidationTasks("avgTravelSpeed", value);
          }
          setAvgTravelSpeed(value);
        }}
        onBlur={() => runValidationTasks("avgTravelSpeed", avgTravelSpeed)}
        errorMessage={errors.avgTravelSpeed?.errorMessage}
        hasError={errors.avgTravelSpeed?.hasError}
        {...getOverrideProps(overrides, "avgTravelSpeed")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Consumul mediu [kWh/100km]:</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        descriptiveText="Vă rugăm introduceți consumul mediu înregistrat exprimat in kWh/100km. Introduceți doar cifra."
        isRequired={true}
        isReadOnly={false}
        placeholder="Exemplu: 17.5"
        type="number"
        step="any"
        value={mediumConsumption}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption: value,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.mediumConsumption ?? value;
          }
          if (errors.mediumConsumption?.hasError) {
            runValidationTasks("mediumConsumption", value);
          }
          setMediumConsumption(value);
        }}
        onBlur={() =>
          runValidationTasks("mediumConsumption", mediumConsumption)
        }
        errorMessage={errors.mediumConsumption?.errorMessage}
        hasError={errors.mediumConsumption?.hasError}
        {...getOverrideProps(overrides, "mediumConsumption")}
      ></TextField>
      <SliderField
        label="Oraș [%]:"
        isDisabled={false}
        descriptiveText="Vă rugăm estimați cât la sută din timp ați condus in Oraș. Introduceți doar cifra."
        isRequired={false}
        value={roadisCity}
        onChange={(e) => {
          let value = e;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity: value,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.roadisCity ?? value;
          }
          if (errors.roadisCity?.hasError) {
            runValidationTasks("roadisCity", value);
          }
          setRoadisCity(value);
        }}
        onBlur={() => runValidationTasks("roadisCity", roadisCity)}
        errorMessage={errors.roadisCity?.errorMessage}
        hasError={errors.roadisCity?.hasError}
        {...getOverrideProps(overrides, "roadisCity")}
      ></SliderField>
      <SliderField
        label="DN [%]:"
        isDisabled={false}
        descriptiveText="Vă rugăm estimați cât la sută din timp ați condus pe drum național. Introduceți doar cifra."
        isRequired={false}
        value={roadisDN}
        onChange={(e) => {
          let value = e;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN: value,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.roadisDN ?? value;
          }
          if (errors.roadisDN?.hasError) {
            runValidationTasks("roadisDN", value);
          }
          setRoadisDN(value);
        }}
        onBlur={() => runValidationTasks("roadisDN", roadisDN)}
        errorMessage={errors.roadisDN?.errorMessage}
        hasError={errors.roadisDN?.hasError}
        {...getOverrideProps(overrides, "roadisDN")}
      ></SliderField>
      <SliderField
        label="Autostradă [%]:"
        isDisabled={false}
        descriptiveText="Vă rugăm estimați cât la sută din timp ați condus pe Autostradă. Introduceți doar cifra."
        isRequired={false}
        value={roadisHighway}
        onChange={(e) => {
          let value = e;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway: value,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.roadisHighway ?? value;
          }
          if (errors.roadisHighway?.hasError) {
            runValidationTasks("roadisHighway", value);
          }
          setRoadisHighway(value);
        }}
        onBlur={() => runValidationTasks("roadisHighway", roadisHighway)}
        errorMessage={errors.roadisHighway?.errorMessage}
        hasError={errors.roadisHighway?.hasError}
        {...getOverrideProps(overrides, "roadisHighway")}
      ></SliderField>
      <SliderField
        label="Alt tip de drum [%]:"
        isDisabled={false}
        descriptiveText="Vă rugăm estimați cât la sută din timp ați condus pe alt tip de drum. Introduceți doar cifra."
        isRequired={false}
        value={roadisOther}
        onChange={(e) => {
          let value = e;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther: value,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.roadisOther ?? value;
          }
          if (errors.roadisOther?.hasError) {
            runValidationTasks("roadisOther", value);
          }
          setRoadisOther(value);
        }}
        onBlur={() => runValidationTasks("roadisOther", roadisOther)}
        errorMessage={errors.roadisOther?.errorMessage}
        hasError={errors.roadisOther?.hasError}
        {...getOverrideProps(overrides, "roadisOther")}
      ></SliderField>
      <TextField
        label="Numărul de pasageri:"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={passengers}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers: value,
              useCase,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.passengers ?? value;
          }
          if (errors.passengers?.hasError) {
            runValidationTasks("passengers", value);
          }
          setPassengers(value);
        }}
        onBlur={() => runValidationTasks("passengers", passengers)}
        errorMessage={errors.passengers?.errorMessage}
        hasError={errors.passengers?.hasError}
        {...getOverrideProps(overrides, "passengers")}
      ></TextField>
      <Autocomplete
        label="Cazul de utilizare:"
        descriptiveText="Vă rugăm selectați un caz de utilizare"
        isRequired={false}
        isReadOnly={false}
        placeholder="Vă rugăm selectați un caz de utilizare"
        options={[
          {
            id: "Urban",
            label: "Urban",
          },
          {
            id: "Navet\u0103",
            label: "Navet\u0103",
          },
          {
            id: "Concediu",
            label: "Concediu",
          },
          {
            id: "Concediu cu portbagaj pe acoperi\u0219",
            label: "Concediu cu portbagaj pe acoperi\u0219",
          },
          {
            id: "Concediu cu Remorca",
            label: "Concediu cu Remorca",
          },
          {
            id: "Biciclete",
            label: "Biciclete",
          },
          {
            id: "Schiuri pe portbagaj pe acoperi\u0219",
            label: "Schiuri pe portbagaj pe acoperi\u0219",
          },
          {
            id: "Altul",
            label: "Altul",
          },
        ]}
        onSelect={({ id, label }) => {
          setUseCase(id);
          runValidationTasks("useCase", id);
        }}
        onClear={() => {
          setUseCase("");
        }}
        defaultValue={useCase}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase: value,
              temp,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.useCase ?? value;
          }
          if (errors.useCase?.hasError) {
            runValidationTasks("useCase", value);
          }
          setUseCase(value);
        }}
        onBlur={() => runValidationTasks("useCase", useCase)}
        errorMessage={errors.useCase?.errorMessage}
        hasError={errors.useCase?.hasError}
        labelHidden={false}
        {...getOverrideProps(overrides, "useCase")}
      ></Autocomplete>
      <TextField
        label="Domeniul de Temperatură [grade Celsius]:"
        descriptiveText="Vă rugăm introduceți Domeniul de Temperatură. Introduceți doar cifra."
        isRequired={false}
        isReadOnly={false}
        placeholder="Exemplu: 17.5"
        type="number"
        step="any"
        value={temp}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp: value,
              evs,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.temp ?? value;
          }
          if (errors.temp?.hasError) {
            runValidationTasks("temp", value);
          }
          setTemp(value);
        }}
        onBlur={() => runValidationTasks("temp", temp)}
        errorMessage={errors.temp?.errorMessage}
        hasError={errors.temp?.hasError}
        {...getOverrideProps(overrides, "temp")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs: values,
              photo,
              freetext,
            };
            const result = onChange(modelFields);
            values = result?.evs ?? values;
          }
          setEvs(values);
          setCurrentEvsValue(undefined);
          setCurrentEvsDisplayValue("");
        }}
        currentFieldValue={currentEvsValue}
        label={
          'Ma\u0219ina electric\u0103 - Nu uita\u021Bi s\u0103 ap\u0103sati "ADD" ce s-a f\u0103cut selec\u021Bia'
        }
        items={evs}
        hasError={errors?.evs?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("evs", currentEvsValue)
        }
        errorMessage={errors?.evs?.errorMessage}
        getBadgeText={getDisplayValue.evs}
        setFieldValue={(model) => {
          setCurrentEvsDisplayValue(model ? getDisplayValue.evs(model) : "");
          setCurrentEvsValue(model);
        }}
        inputFieldRef={evsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label='Mașina electrică - Nu uitați să apăsati "ADD" ce s-a făcut selecția'
          isRequired={false}
          isReadOnly={false}
          placeholder="Click aici pentru a selecta mașina electrică"
          value={currentEvsDisplayValue}
          options={evsRecords.map((r) => ({
            id: getIDValue.evs?.(r),
            label: getDisplayValue.evs?.(r),
          }))}
          isLoading={evsLoading}
          onSelect={({ id, label }) => {
            setCurrentEvsValue(
              evsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentEvsDisplayValue(label);
            runValidationTasks("evs", label);
          }}
          onClear={() => {
            setCurrentEvsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchEvsRecords(value);
            if (errors.evs?.hasError) {
              runValidationTasks("evs", value);
            }
            setCurrentEvsDisplayValue(value);
            setCurrentEvsValue(undefined);
          }}
          onBlur={() => runValidationTasks("evs", currentEvsDisplayValue)}
          errorMessage={errors.evs?.errorMessage}
          hasError={errors.evs?.hasError}
          ref={evsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "evs")}
        ></Autocomplete>
      </ArrayField>
      <Field
        errorMessage={errors.photo?.errorMessage}
        hasError={errors.photo?.hasError}
        label={"Poz\u0103:"}
        descriptiveText={
          "V\u0103 rug\u0103m \u00EEnc\u0103rca\u021Bi o poz\u0103 cu bordul ma\u0219inii electrice. Max 5MB."
        }
        isRequired={false}
        isReadOnly={false}
      >
        {metricRecord && (
          <StorageManager
            defaultFiles={[{ key: metricRecord.photo }]}
            onUploadSuccess={({ key }) => {
              setPhoto((prev) => {
                let value = key;
                if (onChange) {
                  const modelFields = {
                    reportType,
                    retroDate,
                    kmDriven,
                    avgTravelSpeed,
                    mediumConsumption,
                    roadisCity,
                    roadisDN,
                    roadisHighway,
                    roadisOther,
                    passengers,
                    useCase,
                    temp,
                    evs,
                    photo: value,
                    freetext,
                  };
                  const result = onChange(modelFields);
                  value = result?.photo ?? value;
                }
                return value;
              });
            }}
            onFileRemove={({ key }) => {
              setPhoto((prev) => {
                let value = initialValues?.photo;
                if (onChange) {
                  const modelFields = {
                    reportType,
                    retroDate,
                    kmDriven,
                    avgTravelSpeed,
                    mediumConsumption,
                    roadisCity,
                    roadisDN,
                    roadisHighway,
                    roadisOther,
                    passengers,
                    useCase,
                    temp,
                    evs,
                    photo: value,
                    freetext,
                  };
                  const result = onChange(modelFields);
                  value = result?.photo ?? value;
                }
                return value;
              });
            }}
            processFile={processFile}
            accessLevel={"private"}
            acceptedFileTypes={["image/*"]}
            isResumable={false}
            showThumbnails={true}
            maxFileCount={1}
            maxSize={5000000}
            {...getOverrideProps(overrides, "photo")}
          ></StorageManager>
        )}
      </Field>
      <TextField
        label="Text la liber:"
        isRequired={false}
        isReadOnly={false}
        placeholder="Pe scurt vă rugăm introduceți experiențele mai deosebite la drum sau încărcare dacă au fost."
        value={freetext}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              avgTravelSpeed,
              mediumConsumption,
              roadisCity,
              roadisDN,
              roadisHighway,
              roadisOther,
              passengers,
              useCase,
              temp,
              evs,
              photo,
              freetext: value,
            };
            const result = onChange(modelFields);
            value = result?.freetext ?? value;
          }
          if (errors.freetext?.hasError) {
            runValidationTasks("freetext", value);
          }
          setFreetext(value);
        }}
        onBlur={() => runValidationTasks("freetext", freetext)}
        errorMessage={errors.freetext?.errorMessage}
        hasError={errors.freetext?.hasError}
        {...getOverrideProps(overrides, "freetext")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || metricModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || metricModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
