/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserPreferences = /* GraphQL */ `mutation CreateUserPreferences(
  $input: CreateUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  createUserPreferences(input: $input, condition: $condition) {
    id
    weeklyReminder
    hasAcceptedGDPR
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPreferencesMutationVariables,
  APITypes.CreateUserPreferencesMutation
>;
export const updateUserPreferences = /* GraphQL */ `mutation UpdateUserPreferences(
  $input: UpdateUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  updateUserPreferences(input: $input, condition: $condition) {
    id
    weeklyReminder
    hasAcceptedGDPR
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPreferencesMutationVariables,
  APITypes.UpdateUserPreferencesMutation
>;
export const deleteUserPreferences = /* GraphQL */ `mutation DeleteUserPreferences(
  $input: DeleteUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  deleteUserPreferences(input: $input, condition: $condition) {
    id
    weeklyReminder
    hasAcceptedGDPR
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPreferencesMutationVariables,
  APITypes.DeleteUserPreferencesMutation
>;
export const createChargingHistory = /* GraphQL */ `mutation CreateChargingHistory(
  $input: CreateChargingHistoryInput!
  $condition: ModelChargingHistoryConditionInput
) {
  createChargingHistory(input: $input, condition: $condition) {
    id
    chargeDate
    chargeLocation
    chargeType
    chargeQuantity
    chargeCost
    EVS {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChargingHistoryMutationVariables,
  APITypes.CreateChargingHistoryMutation
>;
export const updateChargingHistory = /* GraphQL */ `mutation UpdateChargingHistory(
  $input: UpdateChargingHistoryInput!
  $condition: ModelChargingHistoryConditionInput
) {
  updateChargingHistory(input: $input, condition: $condition) {
    id
    chargeDate
    chargeLocation
    chargeType
    chargeQuantity
    chargeCost
    EVS {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChargingHistoryMutationVariables,
  APITypes.UpdateChargingHistoryMutation
>;
export const deleteChargingHistory = /* GraphQL */ `mutation DeleteChargingHistory(
  $input: DeleteChargingHistoryInput!
  $condition: ModelChargingHistoryConditionInput
) {
  deleteChargingHistory(input: $input, condition: $condition) {
    id
    chargeDate
    chargeLocation
    chargeType
    chargeQuantity
    chargeCost
    EVS {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChargingHistoryMutationVariables,
  APITypes.DeleteChargingHistoryMutation
>;
export const createServiceHistory = /* GraphQL */ `mutation CreateServiceHistory(
  $input: CreateServiceHistoryInput!
  $condition: ModelServiceHistoryConditionInput
) {
  createServiceHistory(input: $input, condition: $condition) {
    id
    odometer
    serviceIn
    serviceOut
    photo
    rootCause
    EVS {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceHistoryMutationVariables,
  APITypes.CreateServiceHistoryMutation
>;
export const updateServiceHistory = /* GraphQL */ `mutation UpdateServiceHistory(
  $input: UpdateServiceHistoryInput!
  $condition: ModelServiceHistoryConditionInput
) {
  updateServiceHistory(input: $input, condition: $condition) {
    id
    odometer
    serviceIn
    serviceOut
    photo
    rootCause
    EVS {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceHistoryMutationVariables,
  APITypes.UpdateServiceHistoryMutation
>;
export const deleteServiceHistory = /* GraphQL */ `mutation DeleteServiceHistory(
  $input: DeleteServiceHistoryInput!
  $condition: ModelServiceHistoryConditionInput
) {
  deleteServiceHistory(input: $input, condition: $condition) {
    id
    odometer
    serviceIn
    serviceOut
    photo
    rootCause
    EVS {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceHistoryMutationVariables,
  APITypes.DeleteServiceHistoryMutation
>;
export const createEV = /* GraphQL */ `mutation CreateEV($input: CreateEVInput!, $condition: ModelEVConditionInput) {
  createEV(input: $input, condition: $condition) {
    id
    friendlyName
    description
    grafanalink
    photo
    Metrics {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    modelID
    servicehistorys {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    status
    charginghistorys {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEVMutationVariables,
  APITypes.CreateEVMutation
>;
export const updateEV = /* GraphQL */ `mutation UpdateEV($input: UpdateEVInput!, $condition: ModelEVConditionInput) {
  updateEV(input: $input, condition: $condition) {
    id
    friendlyName
    description
    grafanalink
    photo
    Metrics {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    modelID
    servicehistorys {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    status
    charginghistorys {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEVMutationVariables,
  APITypes.UpdateEVMutation
>;
export const deleteEV = /* GraphQL */ `mutation DeleteEV($input: DeleteEVInput!, $condition: ModelEVConditionInput) {
  deleteEV(input: $input, condition: $condition) {
    id
    friendlyName
    description
    grafanalink
    photo
    Metrics {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    modelID
    servicehistorys {
      items {
        id
        serviceHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    status
    charginghistorys {
      items {
        id
        chargingHistoryId
        eVId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEVMutationVariables,
  APITypes.DeleteEVMutation
>;
export const createModel = /* GraphQL */ `mutation CreateModel(
  $input: CreateModelInput!
  $condition: ModelModelConditionInput
) {
  createModel(input: $input, condition: $condition) {
    id
    brand
    modelName
    modelType
    yearOfProduction
    EVS {
      items {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    categoryID
    batteryCapacity
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateModelMutationVariables,
  APITypes.CreateModelMutation
>;
export const updateModel = /* GraphQL */ `mutation UpdateModel(
  $input: UpdateModelInput!
  $condition: ModelModelConditionInput
) {
  updateModel(input: $input, condition: $condition) {
    id
    brand
    modelName
    modelType
    yearOfProduction
    EVS {
      items {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    categoryID
    batteryCapacity
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateModelMutationVariables,
  APITypes.UpdateModelMutation
>;
export const deleteModel = /* GraphQL */ `mutation DeleteModel(
  $input: DeleteModelInput!
  $condition: ModelModelConditionInput
) {
  deleteModel(input: $input, condition: $condition) {
    id
    brand
    modelName
    modelType
    yearOfProduction
    EVS {
      items {
        id
        friendlyName
        description
        grafanalink
        photo
        modelID
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    categoryID
    batteryCapacity
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteModelMutationVariables,
  APITypes.DeleteModelMutation
>;
export const createMetric = /* GraphQL */ `mutation CreateMetric(
  $input: CreateMetricInput!
  $condition: ModelMetricConditionInput
) {
  createMetric(input: $input, condition: $condition) {
    id
    reportType
    retroDate
    kmDriven
    avgTravelSpeed
    cost
    mediumConsumption
    roadisCity
    roadisDN
    roadisHighway
    roadisOther
    passengers
    useCase
    temp
    evs {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    photo
    freetext
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetricMutationVariables,
  APITypes.CreateMetricMutation
>;
export const updateMetric = /* GraphQL */ `mutation UpdateMetric(
  $input: UpdateMetricInput!
  $condition: ModelMetricConditionInput
) {
  updateMetric(input: $input, condition: $condition) {
    id
    reportType
    retroDate
    kmDriven
    avgTravelSpeed
    cost
    mediumConsumption
    roadisCity
    roadisDN
    roadisHighway
    roadisOther
    passengers
    useCase
    temp
    evs {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    photo
    freetext
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetricMutationVariables,
  APITypes.UpdateMetricMutation
>;
export const deleteMetric = /* GraphQL */ `mutation DeleteMetric(
  $input: DeleteMetricInput!
  $condition: ModelMetricConditionInput
) {
  deleteMetric(input: $input, condition: $condition) {
    id
    reportType
    retroDate
    kmDriven
    avgTravelSpeed
    cost
    mediumConsumption
    roadisCity
    roadisDN
    roadisHighway
    roadisOther
    passengers
    useCase
    temp
    evs {
      items {
        id
        eVId
        metricId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    photo
    freetext
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMetricMutationVariables,
  APITypes.DeleteMetricMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    name
    grafanalink
    Models {
      items {
        id
        brand
        modelName
        modelType
        yearOfProduction
        categoryID
        batteryCapacity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    name
    grafanalink
    Models {
      items {
        id
        brand
        modelName
        modelType
        yearOfProduction
        categoryID
        batteryCapacity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    name
    grafanalink
    Models {
      items {
        id
        brand
        modelName
        modelType
        yearOfProduction
        categoryID
        batteryCapacity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createChargingHistoryEV = /* GraphQL */ `mutation CreateChargingHistoryEV(
  $input: CreateChargingHistoryEVInput!
  $condition: ModelChargingHistoryEVConditionInput
) {
  createChargingHistoryEV(input: $input, condition: $condition) {
    id
    chargingHistoryId
    eVId
    chargingHistory {
      id
      chargeDate
      chargeLocation
      chargeType
      chargeQuantity
      chargeCost
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChargingHistoryEVMutationVariables,
  APITypes.CreateChargingHistoryEVMutation
>;
export const updateChargingHistoryEV = /* GraphQL */ `mutation UpdateChargingHistoryEV(
  $input: UpdateChargingHistoryEVInput!
  $condition: ModelChargingHistoryEVConditionInput
) {
  updateChargingHistoryEV(input: $input, condition: $condition) {
    id
    chargingHistoryId
    eVId
    chargingHistory {
      id
      chargeDate
      chargeLocation
      chargeType
      chargeQuantity
      chargeCost
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChargingHistoryEVMutationVariables,
  APITypes.UpdateChargingHistoryEVMutation
>;
export const deleteChargingHistoryEV = /* GraphQL */ `mutation DeleteChargingHistoryEV(
  $input: DeleteChargingHistoryEVInput!
  $condition: ModelChargingHistoryEVConditionInput
) {
  deleteChargingHistoryEV(input: $input, condition: $condition) {
    id
    chargingHistoryId
    eVId
    chargingHistory {
      id
      chargeDate
      chargeLocation
      chargeType
      chargeQuantity
      chargeCost
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChargingHistoryEVMutationVariables,
  APITypes.DeleteChargingHistoryEVMutation
>;
export const createServiceHistoryEV = /* GraphQL */ `mutation CreateServiceHistoryEV(
  $input: CreateServiceHistoryEVInput!
  $condition: ModelServiceHistoryEVConditionInput
) {
  createServiceHistoryEV(input: $input, condition: $condition) {
    id
    serviceHistoryId
    eVId
    serviceHistory {
      id
      odometer
      serviceIn
      serviceOut
      photo
      rootCause
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateServiceHistoryEVMutationVariables,
  APITypes.CreateServiceHistoryEVMutation
>;
export const updateServiceHistoryEV = /* GraphQL */ `mutation UpdateServiceHistoryEV(
  $input: UpdateServiceHistoryEVInput!
  $condition: ModelServiceHistoryEVConditionInput
) {
  updateServiceHistoryEV(input: $input, condition: $condition) {
    id
    serviceHistoryId
    eVId
    serviceHistory {
      id
      odometer
      serviceIn
      serviceOut
      photo
      rootCause
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateServiceHistoryEVMutationVariables,
  APITypes.UpdateServiceHistoryEVMutation
>;
export const deleteServiceHistoryEV = /* GraphQL */ `mutation DeleteServiceHistoryEV(
  $input: DeleteServiceHistoryEVInput!
  $condition: ModelServiceHistoryEVConditionInput
) {
  deleteServiceHistoryEV(input: $input, condition: $condition) {
    id
    serviceHistoryId
    eVId
    serviceHistory {
      id
      odometer
      serviceIn
      serviceOut
      photo
      rootCause
      EVS {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteServiceHistoryEVMutationVariables,
  APITypes.DeleteServiceHistoryEVMutation
>;
export const createEVMetric = /* GraphQL */ `mutation CreateEVMetric(
  $input: CreateEVMetricInput!
  $condition: ModelEVMetricConditionInput
) {
  createEVMetric(input: $input, condition: $condition) {
    id
    eVId
    metricId
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    metric {
      id
      reportType
      retroDate
      kmDriven
      avgTravelSpeed
      cost
      mediumConsumption
      roadisCity
      roadisDN
      roadisHighway
      roadisOther
      passengers
      useCase
      temp
      evs {
        nextToken
        __typename
      }
      photo
      freetext
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEVMetricMutationVariables,
  APITypes.CreateEVMetricMutation
>;
export const updateEVMetric = /* GraphQL */ `mutation UpdateEVMetric(
  $input: UpdateEVMetricInput!
  $condition: ModelEVMetricConditionInput
) {
  updateEVMetric(input: $input, condition: $condition) {
    id
    eVId
    metricId
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    metric {
      id
      reportType
      retroDate
      kmDriven
      avgTravelSpeed
      cost
      mediumConsumption
      roadisCity
      roadisDN
      roadisHighway
      roadisOther
      passengers
      useCase
      temp
      evs {
        nextToken
        __typename
      }
      photo
      freetext
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEVMetricMutationVariables,
  APITypes.UpdateEVMetricMutation
>;
export const deleteEVMetric = /* GraphQL */ `mutation DeleteEVMetric(
  $input: DeleteEVMetricInput!
  $condition: ModelEVMetricConditionInput
) {
  deleteEVMetric(input: $input, condition: $condition) {
    id
    eVId
    metricId
    eV {
      id
      friendlyName
      description
      grafanalink
      photo
      Metrics {
        nextToken
        __typename
      }
      modelID
      servicehistorys {
        nextToken
        __typename
      }
      status
      charginghistorys {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    metric {
      id
      reportType
      retroDate
      kmDriven
      avgTravelSpeed
      cost
      mediumConsumption
      roadisCity
      roadisDN
      roadisHighway
      roadisOther
      passengers
      useCase
      temp
      evs {
        nextToken
        __typename
      }
      photo
      freetext
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEVMetricMutationVariables,
  APITypes.DeleteEVMetricMutation
>;
