import React from "react";
import { Icon } from "@aws-amplify/ui-react";

import {
  MdDashboard,
  MdModeEditOutline,
  MdAccountBox,
  MdQueryStats,
  MdCarRepair,
  MdInfo,
  MdEvStation
} from "react-icons/md";

export const baseConfig = {
  projectLink: "/", // GitHub link in the navbar
  docsRepositoryBase: "", // base URL for the docs repository
  titleSuffix: "",
  search: true,
  header: true,
  headerText: "",
  footer: true,
  footerText: (
    <>
      <span>
      UAT Code - © {new Date().getFullYear()}, Made with ❤️ by {""}
        <a href="https://mircea.solutions" target="_blank" rel="noreferrer">
          Mircea
        </a>
      </span>
    </>
  ),

  logo: (
    <>
      <img
        src={process.env.PUBLIC_URL + "/logo_electromobilitate.png"}
        alt="logo electromobilitate"
        width={"200"}
      />
    </>
  ),
};

/// Navigation sidebar
export const appNavs = [
  {
    eventKey: "dashboard",
    icon: <Icon as={MdDashboard} />,
    title: "Dashboard",
    to: "/",
  },
  {
    eventKey: "display-user-metrics",
    icon: <Icon as={MdQueryStats} />,
    title: "Rapoarte",
    to: "/display-user-metrics",
    children: [
      {
        eventKey: "addreport",
        icon: <Icon as={MdModeEditOutline} />,
        title: "Adaugă Raportare",
        to: "/addreport"
      },     
      {
        eventKey: "display-user-metrics",
        icon: <Icon as={MdModeEditOutline} />,
        title: "Raportele Mele",
        to: "/display-user-metrics"
      },      
    ],
  },
  { //charge
    eventKey: "display-user-charginghistory",
    icon: <Icon as={MdEvStation} />,
    title: "Incarcari",
    to: "/display-user-charginghistory",
    children: [
      {
        eventKey: "addcharge",
        icon: <Icon as={MdEvStation} />,
        title: "Adaugă Incarcare",
        to: "/addcharging"
      },     
      {
        eventKey: "display-user-charginghistory",
        icon: <Icon as={MdEvStation} />,
        title: "Incarcarile Mele",
        to: "/display-user-charginghistory"
      },      
    ],
  },  
  {
    eventKey: "display-user-servicehistory",
    icon: <Icon as={MdCarRepair} />,
    title: "Service",
    to: "/display-user-servicehistory",
    children: [
      {
        eventKey: "addservice",
        icon: <Icon as={MdCarRepair} />,
        title: "Adaugă",
        to: "/addservice"
      },
      {
        eventKey: "display-user-servicehistory",
        icon: <Icon as={MdCarRepair} />,
        title: "Istoric",
        to: "/display-user-servicehistory"
      },      
    ],
  },  
  {
    eventKey: "profile",
    icon: <Icon as={MdAccountBox} />,
    title: "Profilul tău",
    to: "/profile",
  },
  {
    eventKey: "about",
    icon: <Icon as={MdInfo} />,
    title: "About",
    to: "/about",
  },
];
