/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { API } from "aws-amplify";
import { listEVS } from "../graphql/queries";
import { createEVMetric, createMetric } from "../graphql/mutations";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function MetricRetro(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    reportType: "",
    retroDate: "",
    kmDriven: "",
    mediumConsumption: "",
    avgTravelSpeed: "",
    cost: "",
    evs: [],
    freetext: "",
  };
  const [reportType, setReportType] = React.useState(initialValues.reportType);
  const [retroDate, setRetroDate] = React.useState(initialValues.retroDate);
  const [kmDriven, setKmDriven] = React.useState(initialValues.kmDriven);
  const [mediumConsumption, setMediumConsumption] = React.useState(
    initialValues.mediumConsumption
  );
  const [avgTravelSpeed, setAvgTravelSpeed] = React.useState(
    initialValues.avgTravelSpeed
  );
  const [cost, setCost] = React.useState(initialValues.cost);
  const [evs, setEvs] = React.useState(initialValues.evs);
  const [evsLoading, setEvsLoading] = React.useState(false);
  const [evsRecords, setEvsRecords] = React.useState([]);
  const [freetext, setFreetext] = React.useState(initialValues.freetext);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setReportType(initialValues.reportType);
    setRetroDate(initialValues.retroDate);
    setKmDriven(initialValues.kmDriven);
    setMediumConsumption(initialValues.mediumConsumption);
    setAvgTravelSpeed(initialValues.avgTravelSpeed);
    setCost(initialValues.cost);
    setEvs(initialValues.evs);
    setCurrentEvsValue(undefined);
    setCurrentEvsDisplayValue("");
    setFreetext(initialValues.freetext);
    setErrors({});
  };
  const [currentEvsDisplayValue, setCurrentEvsDisplayValue] =
    React.useState("");
  const [currentEvsValue, setCurrentEvsValue] = React.useState(undefined);
  const evsRef = React.createRef();
  const getIDValue = {
    evs: (r) => JSON.stringify({ id: r?.id }),
  };
  const evsIdSet = new Set(
    Array.isArray(evs)
      ? evs.map((r) => getIDValue.evs?.(r))
      : getIDValue.evs?.(evs)
  );
  const getDisplayValue = {
    evs: (r) => `${r?.friendlyName ? r?.friendlyName + " - " : ""}${r?.id}`,
  };
  const validations = {
    reportType: [{ type: "Required" }],
    retroDate: [],
    kmDriven: [{ type: "Required" }],
    mediumConsumption: [{ type: "Required" }],
    avgTravelSpeed: [],
    cost: [],
    evs: [],
    freetext: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchEvsRecords = async (value) => {
    setEvsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { friendlyName: { contains: value } },
            { id: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await API.graphql({
          query: listEVS.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listEVS?.items;
      var loaded = result.filter(
        (item) => !evsIdSet.has(getIDValue.evs?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setEvsRecords(newOptions.slice(0, autocompleteLength));
    setEvsLoading(false);
  };
  React.useEffect(() => {
    fetchEvsRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          reportType,
          retroDate,
          kmDriven,
          mediumConsumption,
          avgTravelSpeed,
          cost,
          evs,
          freetext,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            reportType: modelFields.reportType,
            retroDate: modelFields.retroDate,
            kmDriven: modelFields.kmDriven,
            mediumConsumption: modelFields.mediumConsumption,
            avgTravelSpeed: modelFields.avgTravelSpeed,
            cost: modelFields.cost,
            freetext: modelFields.freetext,
          };
          const metric = (
            await API.graphql({
              query: createMetric.replaceAll("__typename", ""),
              variables: {
                input: {
                  ...modelFieldsToSave,
                },
              },
            })
          )?.data?.createMetric;
          const promises = [];
          promises.push(
            ...evs.reduce((promises, ev) => {
              promises.push(
                API.graphql({
                  query: createEVMetric.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      metricId: metric.id,
                      eVId: ev.id,
                    },
                  },
                })
              );
              return promises;
            }, [])
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "MetricRetro")}
      {...rest}
    >
      <TextField
        label="Report type"
        isRequired={true}
        isReadOnly={false}
        value={reportType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType: value,
              retroDate,
              kmDriven,
              mediumConsumption,
              avgTravelSpeed,
              cost,
              evs,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.reportType ?? value;
          }
          if (errors.reportType?.hasError) {
            runValidationTasks("reportType", value);
          }
          setReportType(value);
        }}
        onBlur={() => runValidationTasks("reportType", reportType)}
        errorMessage={errors.reportType?.errorMessage}
        hasError={errors.reportType?.hasError}
        {...getOverrideProps(overrides, "reportType")}
      ></TextField>
      <TextField
        label="Retro date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={retroDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate: value,
              kmDriven,
              mediumConsumption,
              avgTravelSpeed,
              cost,
              evs,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.retroDate ?? value;
          }
          if (errors.retroDate?.hasError) {
            runValidationTasks("retroDate", value);
          }
          setRetroDate(value);
        }}
        onBlur={() => runValidationTasks("retroDate", retroDate)}
        errorMessage={errors.retroDate?.errorMessage}
        hasError={errors.retroDate?.hasError}
        {...getOverrideProps(overrides, "retroDate")}
      ></TextField>
      <TextField
        label="Km driven"
        isRequired={true}
        isReadOnly={false}
        type="number"
        step="any"
        value={kmDriven}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven: value,
              mediumConsumption,
              avgTravelSpeed,
              cost,
              evs,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.kmDriven ?? value;
          }
          if (errors.kmDriven?.hasError) {
            runValidationTasks("kmDriven", value);
          }
          setKmDriven(value);
        }}
        onBlur={() => runValidationTasks("kmDriven", kmDriven)}
        errorMessage={errors.kmDriven?.errorMessage}
        hasError={errors.kmDriven?.hasError}
        {...getOverrideProps(overrides, "kmDriven")}
      ></TextField>
      <TextField
        label="Medium consumption"
        isRequired={true}
        isReadOnly={false}
        value={mediumConsumption}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              mediumConsumption: value,
              avgTravelSpeed,
              cost,
              evs,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.mediumConsumption ?? value;
          }
          if (errors.mediumConsumption?.hasError) {
            runValidationTasks("mediumConsumption", value);
          }
          setMediumConsumption(value);
        }}
        onBlur={() =>
          runValidationTasks("mediumConsumption", mediumConsumption)
        }
        errorMessage={errors.mediumConsumption?.errorMessage}
        hasError={errors.mediumConsumption?.hasError}
        {...getOverrideProps(overrides, "mediumConsumption")}
      ></TextField>
      <TextField
        label="Avg travel speed"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={avgTravelSpeed}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              mediumConsumption,
              avgTravelSpeed: value,
              cost,
              evs,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.avgTravelSpeed ?? value;
          }
          if (errors.avgTravelSpeed?.hasError) {
            runValidationTasks("avgTravelSpeed", value);
          }
          setAvgTravelSpeed(value);
        }}
        onBlur={() => runValidationTasks("avgTravelSpeed", avgTravelSpeed)}
        errorMessage={errors.avgTravelSpeed?.errorMessage}
        hasError={errors.avgTravelSpeed?.hasError}
        {...getOverrideProps(overrides, "avgTravelSpeed")}
      ></TextField>
      <TextField
        label="Cost"
        isRequired={false}
        isReadOnly={false}
        value={cost}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              mediumConsumption,
              avgTravelSpeed,
              cost: value,
              evs,
              freetext,
            };
            const result = onChange(modelFields);
            value = result?.cost ?? value;
          }
          if (errors.cost?.hasError) {
            runValidationTasks("cost", value);
          }
          setCost(value);
        }}
        onBlur={() => runValidationTasks("cost", cost)}
        errorMessage={errors.cost?.errorMessage}
        hasError={errors.cost?.hasError}
        {...getOverrideProps(overrides, "cost")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              mediumConsumption,
              avgTravelSpeed,
              cost,
              evs: values,
              freetext,
            };
            const result = onChange(modelFields);
            values = result?.evs ?? values;
          }
          setEvs(values);
          setCurrentEvsValue(undefined);
          setCurrentEvsDisplayValue("");
        }}
        currentFieldValue={currentEvsValue}
        label={"Evs"}
        items={evs}
        hasError={errors?.evs?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("evs", currentEvsValue)
        }
        errorMessage={errors?.evs?.errorMessage}
        getBadgeText={getDisplayValue.evs}
        setFieldValue={(model) => {
          setCurrentEvsDisplayValue(model ? getDisplayValue.evs(model) : "");
          setCurrentEvsValue(model);
        }}
        inputFieldRef={evsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Evs"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search EV"
          value={currentEvsDisplayValue}
          options={evsRecords.map((r) => ({
            id: getIDValue.evs?.(r),
            label: getDisplayValue.evs?.(r),
          }))}
          isLoading={evsLoading}
          onSelect={({ id, label }) => {
            setCurrentEvsValue(
              evsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentEvsDisplayValue(label);
            runValidationTasks("evs", label);
          }}
          onClear={() => {
            setCurrentEvsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchEvsRecords(value);
            if (errors.evs?.hasError) {
              runValidationTasks("evs", value);
            }
            setCurrentEvsDisplayValue(value);
            setCurrentEvsValue(undefined);
          }}
          onBlur={() => runValidationTasks("evs", currentEvsDisplayValue)}
          errorMessage={errors.evs?.errorMessage}
          hasError={errors.evs?.hasError}
          ref={evsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "evs")}
        ></Autocomplete>
      </ArrayField>
      <TextField
        label="Freetext"
        isRequired={false}
        isReadOnly={false}
        value={freetext}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              reportType,
              retroDate,
              kmDriven,
              mediumConsumption,
              avgTravelSpeed,
              cost,
              evs,
              freetext: value,
            };
            const result = onChange(modelFields);
            value = result?.freetext ?? value;
          }
          if (errors.freetext?.hasError) {
            runValidationTasks("freetext", value);
          }
          setFreetext(value);
        }}
        onBlur={() => runValidationTasks("freetext", freetext)}
        errorMessage={errors.freetext?.errorMessage}
        hasError={errors.freetext?.hasError}
        {...getOverrideProps(overrides, "freetext")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
