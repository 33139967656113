/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://q25pxxvuu5af3hy5s2da4rc6nm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-k6hx6pxc2fgvhcqpsa7szofa6i",
    "aws_cognito_identity_pool_id": "eu-central-1:4a6f9a26-28ce-4034-a7cc-c5dcb396ffee",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_hh6ZA9FMQ",
    "aws_user_pools_web_client_id": "65dsjhutk1busk883nlpp0tb3m",
    "oauth": {
        "domain": "tnvp07f7t2m7-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.electromobilitate.com/",
        "redirectSignOut": "https://app.electromobilitate.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mydashboard-storage-25ae40c9175354-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
