import React from "react";
import { View, Heading, ScrollView } from "@aws-amplify/ui-react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";
//table
import { GraphQLQuery } from "@aws-amplify/api";
import { useEffect, useState } from "react";
import * as queries from "../../../graphql/queries";
import { ListServiceHistoriesQuery } from "@/API";
import { API } from "aws-amplify";
import { Flex } from "@aws-amplify/ui-react";
import { ServiceHistoryValues } from "@/data/fetchValues";
import DisplayServiceHistoryTable from "./DisplayServiceHistoryTable";


const ServiceHistoryTable = () => {
    const [service, setService] = useState<ServiceHistoryValues[]>();
    useEffect(() => {
      async function grabEV() {
        const allServiceHistory = await API.graphql<GraphQLQuery<ListServiceHistoriesQuery>>({
          query: queries.listServiceHistories,
        });
        setService(allServiceHistory.data?.listServiceHistories?.items as ServiceHistoryValues[]);
      }
      grabEV();
    }, []);
  return (
    <>
      <div>
        <h2>Istoric Service</h2>
      </div>

      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth="100%"
        padding="1rem"
        minHeight="80vh"
      >
        <Heading color="#333"> </Heading>
        <br></br>
        <ScrollView width="100%" >
        {service === undefined ? null : (
          <DisplayServiceHistoryTable tableName=" - - " data={service}/> 
        )}
        </ScrollView>
        <div>
          <Flex justifyContent={"center"} alignItems={"center"}>
        <Menu
        trigger={
          
          <MenuButton variation="link" color={"grey"}>Export..
          </MenuButton>
        }
      >
        <MenuItem size="small" onClick={() => alert("Sorry, not available yet")}>Selecteaza Perioada</MenuItem>
        <MenuItem size="small" onClick={() => alert("Sorry, not available yet")}>Toate</MenuItem>
      </Menu> 
      </Flex>
        </div>
      </View>
    </>
  );
};

export default ServiceHistoryTable;
