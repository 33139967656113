import React from "react";
import { View, Heading } from "@aws-amplify/ui-react";
//import { MdDangerous } from "react-icons/md";

const About = () => {
  return (
    <>
      <div>
        <h2>Despre Aplicatie</h2>
      </div>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <Heading color="#333"> Descriere pe scurt: </Heading>
        <div>   
            <p> Aplicația Electromobilitate facilitează automatizarea preluării și prelucrării de date/metrici în beneficiul promovării mobilității electrice dar și pentru amploarea statistică.</p>
        </div>
        <br></br>
        <Heading color="#333"> Versiune: </Heading>
        <div>   
            <p> - Aplicația Electromobilitate este in faza de <b>"Early Access"</b> incepand cu data de 20 Decembrie 2023 si este disponibilă doar pe varianta web pentru o plajă mai restransă de utilizatori.</p>
            <p> - Vă multumim pentru răbdare, interes si feedback !</p>
            <p><a href="https://chat.mircea.solutions/signup_user_complete/?id=&md=link&sbr=fa">Click aici pentru a raporta o problema pe canalul nostru de suport </a></p>
        </div>
        <br></br>
        <Heading color="#333"> Despre Datele Dumneavoastră: </Heading>
        <div>   
            <p> Aplicația Electromobilitate <b>nu colectează date personale </b> cu exceptia adresei de email care este folosita pentru autentificare. </p> 
            <p> Datele de autentificare dar si datele masinii raman stocate doar in contul Electromobilitate si sunt folosite doar in folosul comunitatii Electromobilitate si a participantiilor la proiect. </p> 
            <p> Scopul principal este <b>promovarea mobilității electrice</b> precum este mentionat in descrierea aplicatiei. </p>
        </div>
        <br></br>
        <Heading color="#FF0000"> "Bug-uri" / Probleme deja cunoscute: </Heading>
        <div>   
            <p> Aplicația Electromobilitate <b>nu este perfectă </b> si posibil sa existe diferite probleme care se descoperă in timp ce este utilizată tot mai des. </p> 
            <p> Printre cele cunoscute se numară:</p>
            <p> - problema daca se da "refresh" la o pagina care nu este cea principala: "HTTP ERROR 404" </p>
            <p> - nu incape scrisul: daca sunteti pe telefon, va rugam rotiti telefonul pe orizontala - <b> aplicatia nu este "mobile ready"</b> inca..</p>
        </div>
        <br></br>
        <Heading color="#006400"> "Bug-uri" rezolvate: </Heading>
        <div>   
            <p> - dupa ce s-a introdus date nu apar in "rapoartele mele": am incercat sa introduc mai multe date retroactive deodata si a dat peste cap strctura bazei de date.</p>
            <p> - mutat graficele pe data retroactiva </p>
            <p> - nu exsta feedback dupa ce se updata o metrica sau o intrare in service</p>  
        </div>
        <br></br>
        <Heading color="#333"> Features pe viitor: </Heading>
        <div> 
            <p> Dacă aplicatia se bucură de succes si exista interes pentru utilizare - atunci pe roadmap am inclus: </p>
            <p> - o metoda de vizualizat raportarile mai intuitiva </p>  
            <p> - github to submit / manage errors and bugs + collaboration </p> 
            <p> - autocompletare date in functie de raportul selectat. </p>  
            <p> - detectie din poza a kilometriilor parcursi si autocompletare in raportare </p> 
            <p> - login cu facebook </p>
            <p> - login cu apple </p>
            <p> - grafice din grafana pentru categoria din care masina face parte pentru ca utilizatorul sa se compare cu alti participanti la proiect </p>
            <p> - "design/facelift" la grafice </p>
        </div>
      </View>

     
    </>
  );
};

export default About;
