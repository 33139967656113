import React from "react";
import { View, Heading, ScrollView } from "@aws-amplify/ui-react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";
//table -
import { GraphQLQuery } from "@aws-amplify/api";
import { useEffect, useState } from "react";
import * as queries from "../../../graphql/queries";
import { ListChargingHistoriesQuery } from "@/API";
import { API } from "aws-amplify";
import { Flex } from "@aws-amplify/ui-react";
import { ChargingHistoryValues } from "@/data/fetchValues";
import DisplayChargingHistoryTable from "./DisplayChargingHistoryTable";


const ChargingHistoryTable = () => {
    const [Charging, setCharging] = useState<ChargingHistoryValues[]>();
    useEffect(() => {
      async function grabEV() {
        const allChargingHistory = await API.graphql<GraphQLQuery<ListChargingHistoriesQuery>>({
          query: queries.listChargingHistories,
        });
        setCharging(allChargingHistory.data?.listChargingHistories?.items as ChargingHistoryValues[]);
      }
      grabEV();
    }, []);
  return (
    <>
      <div>
        <h2>Istoric Charging</h2>
      </div>

      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth="100%"
        padding="1rem"
        minHeight="80vh"
      >
        <Heading color="#333"> </Heading>
        <br></br>
        <ScrollView width="100%" >
        {Charging === undefined ? null : (
          <DisplayChargingHistoryTable tableName=" - - " data={Charging}/> 
        )}
        </ScrollView>
        <div>
          <Flex justifyContent={"center"} alignItems={"center"}>
        <Menu
        trigger={
          
          <MenuButton variation="link" color={"grey"}>Export..
          </MenuButton>
        }
      >
        <MenuItem size="small" onClick={() => alert("Sorry, not available yet")}>Selecteaza Perioada</MenuItem>
        <MenuItem size="small" onClick={() => alert("Sorry, not available yet")}>Toate</MenuItem>
      </Menu> 
      </Flex>
        </div>
      </View>
    </>
  );
};

export default ChargingHistoryTable;
