import { API } from 'aws-amplify';
import { createMetric } from '../graphql/mutations';

interface RetroactiveMetric {
  reportType: string; 
  mediumConsumption: string;
  kmDriven: number;
  freetext: string;
  retroDate: string;
}

async function ImportData(data: RetroactiveMetric[]) {
  try {
    const promises = data.map(metric => {
      return API.graphql({
        query: createMetric, 
        variables: {
          input: metric
        }
      });
    });

    await Promise.all(promises);
    alert('Import successful!');
  } catch (error) {
    console.error('Failed to import data: ', error);
  }
}

export default ImportData;