import React from "react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

//table imports:
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { ChargingHistoryValues } from "@/data/fetchValues";
// First, import the deleteChargingHistorymutation
import * as mutations from "../../../graphql/mutations";
import { API } from "aws-amplify";
import { useState } from "react";
import { 
  MdModeEdit,
  MdDriveFileRenameOutline,
  MdDelete 
} from "react-icons/md";

interface ItemsTableProps {
  tableName: string;
  data: ChargingHistoryValues[];
}

export default function ChargingHistoryTable({ tableName, data }: ItemsTableProps) {
    const navigate = useNavigate();
      // Sort data by chargeDate
      const sortedData = [...data].sort((a, b) => {
        const dateA = a.chargeDate ? new Date(a.chargeDate).getTime() : -Infinity;
        const dateB = b.chargeDate ? new Date(b.chargeDate).getTime() : -Infinity;
        
        return dateB - dateA;
      });
    // The deleteChargingHistoryfunction could look something like this:
    const [Charging, setCharging] = useState<ChargingHistoryValues[]>(); 
    const deleteChargingHistory = async (id: string) => {
      // Confirm with the user
    const confirm = window.confirm("Sunteti sigur/a ca doriti sa stergeti aceasta Incarcare ?");
    if (!confirm) return;  // If the user clicks "Cancel", stop here
      try {
        const ChargingToDelete = {
          id, // Id of the ChargingHistoryyou want to delete
        };
  
        await API.graphql({
          query: mutations.deleteChargingHistory, 
          variables: { input: ChargingToDelete } 
        });
  
        // Then you can update your local state to remove the deleted metric
        if (Charging) {
          setCharging(Charging.filter(item => item.id !== id));
        }
        // After deletion, navigate and refresh the page:
        // window.location.href = "/display-user-Charginghistory";
        navigate('/');
      } catch (error) {
        console.log("Error deleting Charging history:", error);
      }
    };  
  return (
    <Table caption={tableName}>
      <TableHead>
        <TableRow>
          <TableCell as="th">Data Incarcarii</TableCell>
          <TableCell as="th">Locatia</TableCell>
          <TableCell as="th">Tip</TableCell>
          <TableCell as="th">Cantitate (kWh)</TableCell>
          <TableCell as="th">Cost (RON)</TableCell>
          <TableCell as="th">Actiuni</TableCell>                     
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map((item, key) => (
          <TableRow key={key}>
            <TableCell>{Intl.DateTimeFormat("ro-RO").format(new Date(item?.chargeDate!))}</TableCell>
            <TableCell>{item.chargeLocation}</TableCell>
            <TableCell>{item.chargeType}</TableCell>            
            <TableCell>{item.chargeQuantity}</TableCell>
            <TableCell > {item.chargeCost}</TableCell>
            <TableCell as="th">
            <Menu
        menuAlign="end"
        trigger={
          
          <MenuButton variation="primary" size="small" color={"ButtonHighlight"}>
            <MdModeEdit />
          </MenuButton>
        }
      >
        <MenuItem size="small" onClick={() => navigate(`/updateCharging/${item.id}`)} ><MdDriveFileRenameOutline /> EDIT</MenuItem>
        <MenuItem 
          size="small" 
          variation="destructive" 
          color={"red.40"} 
          onClick={() => deleteChargingHistory(item.id)}  // Call deleteMetric with the id of the item
        >
          <MdDelete /> DELETE
        </MenuItem>
      </Menu>
              
              
              </TableCell>                              
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}