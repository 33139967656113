import React, {
  useEffect, 
  useState 
} from "react";
import {   
  Menu, 
  MenuItem, 
  MenuButton,  
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
// import { AiFillGithub } from "react-icons/ai";
// import { baseConfig } from "../../config";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import awsExports from '../../aws-exports';
import { Auth } from "@aws-amplify/auth";

Amplify.configure(awsExports);

interface UserAttributes {
  picture: string;
  profile: string;
  // other attributes
}

const HeaderNav = () => {
  const navigate = useNavigate();
  const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(null);
  const { signOut } = useAuthenticator((context) => [context.user]);
  // const { toResetPassword } = useAuthenticator((context) => [context.toResetPassword]);
  
  useEffect(() => {
    async function fetchAttributes() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
        const attributes = currentUser.attributes;
        // console.log(attributes)
        setUserAttributes(attributes);
      } catch (error) {
        console.log(error);
      }
    }
    fetchAttributes();
  }, []);
  
  return (
    <>
      <Menu
        menuAlign="end"
        trigger={
          
          <MenuButton variation="menu" size="small">
            <div className="header-avatar">
            <img 
              alt="avatar" 
              src={
                userAttributes?.picture 
                ? userAttributes.picture 
                : "https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-man-avatar-wearing-blue-t-shirt-png-image_5809518.png"
                  }/>
            </div>
          </MenuButton>
        }
      >
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
        <MenuItem onClick={signOut}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default HeaderNav;
