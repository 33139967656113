import React, { useEffect, useState } from "react";
import {
  View, Text, Button
} from "@aws-amplify/ui-react";
import EVCreateForm from "../../ui-components/EVCreateForm";
import { useNavigate } from "react-router-dom";
import { 
  MdOutlineCarCrash
} from "react-icons/md";
import * as queries from "../../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api"
import { ListModelsQuery } from "@/API";
import { GetModelValues } from "@/data/fetchValues";
import { API } from "aws-amplify";


const BasicAdd = () => {

  const [modelid, setModelID] = useState<GetModelValues[]>();

      //Total Metrici Inregistrate
      const TotalNrOfModels = modelid === undefined 
      ? '0' 
      : modelid.length.toString();

  useEffect(() => {
    async function grabChargeType(){
      const allModels = await API.graphql<GraphQLQuery<ListModelsQuery>>({
        query: queries.listModels,
      });
      setModelID(allModels.data?.listModels?.items as GetModelValues[]);
    }
    grabChargeType();
  },[]);

  const navigate = useNavigate();
  const handleIconClick = () => {
    navigate('/addmodel');  // Replace with your website URL
  };

  const handleTextClick = () => {
    navigate('/addmodel');  // Replace with your website URL
  };
  const handleSuccess = () => {
    alert('Multumim. Adaugat cu Succes.');
    navigate('/profile');}
  return (
    <>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <EVCreateForm 
        onSuccess={handleSuccess} 
        onError={() => alert("Ne pare rau a aparut o Eroare. Daca eroarea persista va rugam sa ne spuneti")}        
        /> 
      <View textAlign="left" >
          <Text>
          <span style={{ color: "red" }}>
          <MdOutlineCarCrash size={"27px"} onClick={handleIconClick} style={{ cursor: "pointer" }}/><br></br>
            Intampinati probleme? Cumva nu gasiti modelul dvs. de masina in cele {TotalNrOfModels} disponibile in baza noastra de date ?
            </span>
            <br></br>
            Pana vom inbunatatii acest formular (nu foarte intuitiv) - va rugam sa va asigurati ca: <br></br>
            - Tastati macar brand-ul in box-ul de cautare, daca nu tastati - se vor afisa doar cateva modele.<br></br>
            - Box-ul de cautare este Case Sensitive. Ex:  Daca cautati dupa brand, prima litera este mare.<br></br>
            - Daca ati gasit si selectat modelul masinii, atunci apasati butonul de "Add" din dreapta.<br></br><br></br>
            <Text> Daca tot nu exista modelul dvs. nicio problema! </Text>
            <Button onClick={handleTextClick} variation="warning">
          Adauga un model nou
         </Button>
          </Text>
        </View> 
      </View>
    </>
  );
};

export default BasicAdd;
