import React from "react";
import { View, Heading } from "@aws-amplify/ui-react";
import AddMetricForm from "./AddMetricForm";
//import { MdEditCalendar } from "react-icons/md";
//hooks:
import { useUserHasEVs } from '../hooks/useUserHasEVs';

function getWeekNumberAndDate() {
  let now = new Date();
  now.setDate(now.getDate() - 7); // Subtract seven days to get the previous week

  const weekNumber = Math.ceil(((now.getTime() - new Date(now.getFullYear(), 0, 1).getTime()) / 604800000)); // 604800000 ms in a week

  const dayOfWeek = now.getDay();
  const weekStart = new Date(now.valueOf() - (dayOfWeek <= 0 ? 6 : dayOfWeek - 1) * 86400000); // 86400000 ms in a day
  const weekEnd = new Date(weekStart.valueOf() + 6 * 86400000);

  const weekStartRO = weekStart.toLocaleDateString('ro-RO');
  const weekEndRO = weekEnd.toLocaleDateString('ro-RO');

  return {
    weekNumber,
    weekStartRO,
    weekEndRO,
  };
}

function getFirstMondayOfPreviousMonth() {
  const now = new Date();
  const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  
  // This loop will find the first Monday of the month
  while (previousMonth.getDay() !== 1) {
    previousMonth.setDate(previousMonth.getDate() + 1);
  }
  
  return previousMonth.toLocaleDateString('ro-RO');
}

function getCurrentDate() {
  const now = new Date();
  return now.toLocaleDateString('ro-RO');
}

//console.log(getWeekNumberAndDate());

const Reports = () => {
  useUserHasEVs();
  const now = getCurrentDate();
  const weekDetails = getWeekNumberAndDate();
  const firstMondayOfPreviousMonth = getFirstMondayOfPreviousMonth();

  return (
    <>
      <div>
        <h2>Reports</h2>
      </div>
      <View
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        padding="1rem"
        minHeight="100vh"
      >
        <Heading color="#333"> 
        Adauga Raportare
        </Heading>
        
          <div>
          <p>- Raport Săptămânal(CW{weekDetails.weekNumber}): In raport introduceti data de <b> {weekDetails.weekStartRO} </b>. </p> 
          <p>- Raport Lunar: In raport introduceti data de <b>{firstMondayOfPreviousMonth} </b>. </p> 
          <p>- Raport Zilnic: Introduceti data de: <b>{now} </b></p> 
          <p>- Raport Retroactiv: Introduceti data din trecut pe care o aveti notată. </p>
          <p>- Incarcare: Introduceti data la care s-a efectuat incarcărea si la Kilometrii Parcursi "0" - dacă este cazul. </p>
          </div>
         <View/>
        <AddMetricForm />
      </View>
    </>
  );
};

export default Reports;